import React, { useState, useEffect } from "react";
import {
  getDocs,
  collection,
  orderBy,
  query,
  doc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "./Firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import LowControl from "./LowControl";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { haventpaid } from "./Customhooks/PayDue.js";
import PayDemand from "./Customhooks/PayDemand.js";
import "./MessageView.css";

function MessageView() {
  const navigate = useNavigate();
  const location = useLocation();

  const [messages, setMessages] = useState([]);
  const [pledgemessages, setPledgemessages] = useState([]);
  const [rankmessages, setRankmessages] = useState([]);
  const [id, setId] = useState("");
  const [allmessage, setAllmesssge] = useState(true);
  const [pledgemessage, setPledgemessage] = useState(false);
  const [rankmessage, setRankmessage] = useState(false);
  const [searchmessage, setSearchmessage] = useState(false);
  const [paywarn, setPaywarn] = useState("");

  useEffect(() => {
    console.log(location.state);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user.uid);
        setId(user.uid);
        /*const paid = async () => {
          let result = await new Promise((resolve) => {
            return resolve(haventpaid(user.uid));
          });
          return result;
        };*/

        //add stipulation that it has to also be past 3 days
        //promise.all the bottom getdocs as well as haventpaid to make it faster

        const isit = await haventpaid(user.uid);
        setPaywarn(isit);
        console.log(isit, "GRRRRRRRRRRRRR");

        const userCollectionRef1 = collection(
          db,
          "users",
          user.uid,
          "PledgeHold"
        );
        await getDocs(userCollectionRef1).then((querySnapshot) => {
          let Pledger = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          console.log(Pledger);

          const userCollectionRef = collection(
            db,
            "users",
            user.uid,
            "Message"
          );
          getDocs(query(userCollectionRef, orderBy("Timestamp", "desc"))).then(
            (querySnapshot) => {
              let posts = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }));

              Pledger.forEach((post) => {
                posts.map((element) => {
                  if (post.PaidUser === element.from) {
                    element.Pledge = true;
                  }
                });
              });
              setTimeout(() => {
                setMessages(posts);
              });
            }
          );
        });

        updateDoc(doc(db, `users/${user.uid}/`), {
          MessageNotification: 0,
        });
      }
    });
  }, []);

  const read = (e) => {
    console.log(e.target.id.split(","));
    let tempid = e.target.id.split(",");
    if (tempid[3] === "false") {
      updateDoc(doc(db, `users/${id}/Message/${tempid[1]}`), {
        Opened: true,
      });
    }
    setTimeout(() => {
      navigate(`/Message/${tempid[1] === id ? tempid[2] : tempid[1]}`, {
        state: ["MessageView", location.state],
      });
    });
  };

  const filterleft = (e) => {
    if (pledgemessage) {
      setAllmesssge(true);
      setPledgemessage(false);
    } else if (rankmessage && !searchmessage) {
      setPledgemessage(true);
      setRankmessage(false);
      setPledgemessages(messages.filter((message) => message.Pledge));
    } else if (searchmessage) {
      setSearchmessage(false);
    }
  };

  const filterright = (e) => {
    if (allmessage) {
      setAllmesssge(false);
      setPledgemessage(true);

      setPledgemessages(messages.filter((message) => message.Pledge));
    } else if (pledgemessage) {
      setPledgemessage(false);
      setRankmessage(true);
      setRankmessages(
        [...messages].sort((a, b) => a.MessageRank - b.MessageRank)
      );
    } else if (rankmessage) {
      setSearchmessage(true);
    }
  };

  const backurl1 = () => {
    /* if (location.state !== "UserView" && location.state !== "User") {
      navigate(`/${location.state}`);
    } else if (location.state === "User") {
      navigate(`/${location.state}/${id}`);
    }*/

    //once we allow the lowcontrol in pledge and userview and shiz that require
    //ids as param in the url, then the path needs to path += `/${id}` as well
    let path = `/${location.state}`;
    if (location.state === "User") path += `/${id}`;
    navigate(path);
  };
  const closepaywarn = () => {
    setPaywarn("");
  };

  return (
    <div>
      <img
        style={{ zIndex: 2, position: "absolute", top: 15, left: 4 }}
        src="/Arrow1.svg"
        onClick={backurl1}
      />
      {paywarn && (
        <div
          style={{
            position: "fixed",
            top: "30%",
            width: "100%",
            height: 400,
            zIndex: 2,
          }}
        >
          {<PayDemand demandinfo={paywarn} close={closepaywarn} />}
        </div>
      )}
      {
        //her name,new mark with border
        //the new uploaded photo blured,
        //clickable to her page
        //////////////////////////////////
        //weekend!!
        //different randomize text in suggesteed user
        //actually dont allow the photo to be saved through on touch events and other means
        //notification to the dude when he succeessfully paid make it look really nice
        //notification to the girl after someone joins pledge
        //notification that plan is about to expire
        //ways to pay for multiple months for discounts
        //randomize fullscreen promotion chick other than noa and にゃあ
        //phone compoenet set up
        //next big mission
        ///////////////////////////////////////////////
        //can you manualy save images on fullscreen promotion as well? if so stop that
        //clickable to her page
        //make sure the notification is clear the its a photos, because it can be videos or phones or whatever
        ////////////////////////////////////
        //ACTUALLY keep the pay notification WITHOUT 3 days but put at the bottom
        //add if you paid already wait till the money processses in the pay reminder notification
        //the pay due should be lil lower within the box
        //if rank is above 999 than the pixel has to get smaller ( just changing the marginleft isnt good enough anymore)
        //fontSize:
        //need to decideif you are allowed to skip this payment demand in 10 seconds or nah
        //notification for BOTH pledge subscribe when its about to expire and when it expires
        //however this should not be notified all the time only sometimes しつこすぎず
      }

      {
        //fix new date being called so many times in the dom
      }

      {
        //PayPay pay method should be separate component in pledge subscribe
        //and in paydemand
        /////
        ///in pledgesubscribe all payment methods loader should be different
        //component currently its all loaded by stripe element and thats wrong
        //if rank gets higher than 1000 user posts, userview posts, connect posts and comments
        //implement wavy line in pledge
        //more space below camera and pledge photo
        //maybe ring borders around pledgesuggestion profile image??
        //add borders to the おすすめユーザーtext
        //the function read should be on all 3? images and videos as well? or thats too much
        //should the haventpaid be awaited slows down for users that dont have any pending pay
        //too many paywarn questioning? any way to not ask too much
        //clicking to message there is error of blocked change && to if statement NOT A HUGE ERROR!
        //the whole messages mapp should be in different component since it can be filtered with ranks and pledge 順
        //make the post pluggable by making separate component
        //make the comments pluggable by making separate component
        //replace MOST back button with simply the BROWSER command history.push type shiz
        //no need to write our own url paths for each back button EXCEPT for a few
        //make the timer COUNTDOWN in loadanimation in separate component so it doesnt rerender the whole component for each countdown
        ///integrate timer < 1 of onclick and timer < 1 in スキップ so its not asked twice
        //but jst once
        //fix in message page so it doesnt ask this on each rerender let Fullscreenstyleson = fullscreentarget.length ? Fullscreenstyles : {};
        //for the message component paywarn reduce the "asking"
        //in the suggested user, dont querry the user that you are viewing
        //make notification more noticable
        //clean up notification component
      }

      {/* <div className="allrecieve">
        {<img style={{ width: 20 }} src="/Arrow1.svg" />
        <span className="filterline">
          {!allmessage && <span onClick={filterleft}>arrowleft </span>}
          {allmessage && <span>all icon</span>}
          {pledgemessage && <img style={{ width: 20 }} src="/Pledge1.svg" />}
          {rankmessage && <span>rank icon</span>}
          {searchmessage && <input placeholder="🔍"></input>}
          {!searchmessage && <span onClick={filterright}>arrowright</span>}
        </span>}
      </div>
*/}
      <div
        style={{
          width: "100%",
          height: 56,
          display: "block",
          background: "#005280",
          zIndex: 1,
          marginBottom: 2,
        }}
      ></div>
      {
        //cant do this since on mobile you cant click on some of the profile_image
        //layer zindex issue
        //<div style={{ filter: paywarn && "blur(3px)" }}></div>
      }
      <div style={{ filter: paywarn && "blur(3px)" }}>
        {allmessage &&
          messages.map((message, index) => (
            <div
              className="drops2"
              key={message.id}
              style={{
                // marginBottom:
                /*message.id === messages[messages.length - 1]?.id*/
                // index === messages.length - 1 && 0,
                borderBottom: index === messages.length - 1 && 0,

                //filter: paywarn && "blur(3px)",
              }}
            >
              <img
                src={message.MessagePic}
                className="current4"
                onClick={(e) =>
                  navigate(
                    `/UserView/${
                      message.from === id ? message.to : message.from
                    }`
                  )
                }
                style={{
                  background:
                    message.MessagePic === "/Profile3.svg" &&
                    "linear-gradient(#121112, #42b1e0)",
                  marginTop: !message.text && 3,
                }}
              />

              <span
                id={[message.id, message.from, message.to, message.Opened]}
                className="alldrops1"
                onClick={!paywarn ? read : undefined}
              ></span>
              <div className="messageline">
                <div className="messagename">
                  <span
                    style={{
                      background: "linear-gradient(#b042b0, #42b1e0)",
                      borderRadius: 20,
                      paddingRight: 5,
                      paddingLeft: 5,
                    }}
                  >
                    {message.from === id
                      ? message.RecieveName
                      : message.MessageName}
                  </span>
                  {message.Pledge && (
                    <img style={{ width: 11 }} src="/Pledge1.svg" />
                  )}
                  {new Date().getTime() - message.Timestamp.seconds * 1000 <
                  60000 ? (
                    <div style={{ fontSize: 12, marginLeft: 5, marginTop: 2 }}>
                      {Math.floor(
                        (new Date().getTime() -
                          message.Timestamp.seconds * 1000) /
                          1000
                      )}
                      秒前
                    </div>
                  ) : new Date().getTime() - message.Timestamp.seconds * 1000 <
                    3600000 ? (
                    <div style={{ fontSize: 12, marginLeft: 5, marginTop: 2 }}>
                      {Math.floor(
                        (new Date().getTime() -
                          message.Timestamp.seconds * 1000) /
                          60000
                      )}
                      分前
                    </div>
                  ) : new Date().getTime() - message.Timestamp.seconds * 1000 <
                    86400000 ? (
                    <div style={{ fontSize: 12, marginLeft: 5, marginTop: 2 }}>
                      {Math.floor(
                        (new Date().getTime() -
                          message.Timestamp.seconds * 1000) /
                          3600000
                      )}
                      時間前
                    </div>
                  ) : new Date().getTime() - message.Timestamp.seconds * 1000 >
                      86400000 &&
                    new Date().getTime() - message.Timestamp.seconds * 1000 <
                      604800000 ? (
                    <div style={{ fontSize: 12, marginLeft: 5, marginTop: 2 }}>
                      {Math.floor(
                        (new Date().getTime() -
                          message.Timestamp.seconds * 1000) /
                          86400000
                      )}
                      日前
                    </div>
                  ) : (
                    <div style={{ fontSize: 12, marginLeft: 5, marginTop: 2 }}>
                      {message.Timestamp.toDate().getFullYear() +
                        "/" +
                        (
                          "0" +
                          (message.Timestamp.toDate().getMonth() + 1)
                        ).slice(-2) +
                        "/" +
                        ("0" + message.Timestamp.toDate().getDate()).slice(-2)}
                    </div>
                  )}
                </div>
                <div className="messagetext">{message.text}</div>
                {message.image1 && (
                  <div>
                    <img
                      style={{
                        width: 50,
                        borderRadius: 15,
                      }}
                      src={message.image1}
                      id={[
                        message.id,
                        message.from,
                        message.to,
                        message.Opened,
                      ]}
                      onClick={
                        !paywarn ? read : undefined
                        //message.text ? read : undefined
                      }
                    />
                  </div>
                )}
                {message.video && (
                  <video
                    id={[message.id, message.from, message.to, message.Opened]}
                    onClick={!paywarn ? read : undefined}
                    width="100"
                    height="70"
                    controls
                    autoPlay={message.Opened === false && true}
                    playsInline
                    muted
                    loop
                    style={{
                      objectFit: "cover",
                      borderRadius: 15,
                    }}
                  >
                    <source type="video/mp4" src={message.video} />
                  </video>
                )}
              </div>
              <span className="newmessage">
                {message.Opened === false && <img src="/NewMessage.svg" />}
              </span>
            </div>
          ))}
        {/*pledgemessage &&
          pledgemessages.map((message) => (
            <div className="drops2" key={message.id}>
              <img
                src={message.MessagePic}
                className="current4"
                onClick={(e) =>
                  navigate(
                    `/UserView/${
                      message.from === id ? message.to : message.from
                    }`
                  )
                }
                style={{
                  background:
                    message.MessagePic === "/Profile3.svg" &&
                    "linear-gradient(#121112, #42b1e0)",
                  marginTop: !message.text && 3,
                }}
              />
              <span
                id={[message.id, message.from, message.to, message.Opened]}
                className="alldrops1"
                onClick={!paywarn ? read : undefined}
              ></span>

              <div className="messageline">
                <div className="messagename">
                  <span
                    style={{
                      background: "linear-gradient(#b042b0, #42b1e0)",
                      borderRadius: 20,
                      paddingRight: 5,
                      paddingLeft: 5,
                    }}
                  >
                    {message.from === id
                      ? message.RecieveName
                      : message.MessageName}
                  </span>
                  {message.Pledge && (
                    <img style={{ width: 11 }} src="/Pledge1.svg" />
                  )}
                </div>
                <div className="messagetext">{message.text}</div>
                {message.image1 && (
                  <div>
                    <img
                      style={{
                        width: 50,
                        borderRadius: 15,
                      }}
                      src={message.image1}
                      id={[
                        message.id,
                        message.from,
                        message.to,
                        message.Opened,
                      ]}
                      onClick={
                        !paywarn ? read : undefined
                        //message.text ? read : undefined
                      }
                    />
                  </div>
                )}
                {message.video && (
                  <video
                    id={[message.id, message.from, message.to, message.Opened]}
                    onClick={!paywarn ? read : undefined}
                    width="100"
                    height="70"
                    controls
                    playsInline
                    muted
                    loop
                    style={{
                      objectFit: "cover",
                      borderRadius: 15,
                    }}
                  >
                    <source type="video/mp4" src={message.video} />
                  </video>
                )}
              </div>
              <span className="newmessage">
                {message.Opened === false && <img src="/NewMessage.svg" />}
              </span>
            </div>
          ))*/}
        {rankmessage &&
          rankmessages.map((message) => (
            <div className="drops2" key={message.id}>
              <img
                src={message.MessagePic}
                className="current4"
                onClick={(e) =>
                  navigate(
                    `/UserView/${
                      message.from === id ? message.to : message.from
                    }`
                  )
                }
                style={{
                  background:
                    message.MessagePic === "/Profile3.svg" &&
                    "linear-gradient(#121112, #42b1e0)",
                  marginTop: !message.text && 3,
                }}
              />
              <span
                id={[message.id, message.from, message.to, message.Opened]}
                className="alldrops1"
                onClick={read}
              ></span>

              <div className="messageline">
                <div className="messagename">
                  <span
                    style={{
                      background: "linear-gradient(#b042b0, #42b1e0)",
                      borderRadius: 20,
                      paddingRight: 5,
                      paddingLeft: 5,
                    }}
                  >
                    {message.from === id
                      ? message.RecieveName
                      : message.MessageName}
                  </span>
                  {message.Pledge && (
                    <img style={{ width: 11 }} src="/Pledge1.svg" />
                  )}
                </div>
                <div className="messagetext">{message.text}</div>
                {message.image1 && (
                  <div>
                    <img
                      style={{
                        width: 50,
                        borderRadius: 15,
                      }}
                      src={message.image1}
                      id={[
                        message.id,
                        message.from,
                        message.to,
                        message.Opened,
                      ]}
                      onClick={message.text && read}
                    />
                  </div>
                )}
                {message.video && (
                  <video
                    id={[message.id, message.from, message.to, message.Opened]}
                    onClick={read}
                    width="100"
                    height="70"
                    controls
                    playsInline
                    muted
                    loop
                    style={{
                      objectFit: "cover",
                      borderRadius: 15,
                    }}
                  >
                    <source type="video/mp4" src={message.video} />
                  </video>
                )}
              </div>
              <span className="newmessage">
                {message.Opened === false && <img src="/NewMessage.svg" />}
              </span>
            </div>
          ))}
      </div>
      {
        //</div>
      }

      <LowControl currenturl={"MessageView"} />
    </div>
  );
}

export default MessageView;
