import React, { useState, useEffect } from "react";
import { getDocs, query, collection, orderBy, limit } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./Firebase.js";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./FullScreenPromotion.css";
import LoadingAnimation from "./Customhooks/LoadingAnimation.js";

function FullScreenPromotion({ id, userinfo }) {
  const allpromos = [
    ["./EpliseGirlNeon1.svg", ""],
    ["./nyunpromo5.svg", "Message/b3wZEloxcxRwAZ9fsYYzhjRaQBF3"],
    ["PledgePromotionFinal1.svg", "Pledge/3qUZiqcUx1RCWPVZdVBkHgjotlp2"],
    ["PledgePromotionFinalNoa1.svg", "Pledge/NkDFoKgVP5TC0LKZaRwm6yaSYt73"],
  ];

  const navigate = useNavigate();
  const [promo, setPromo] = useState([]);
  const [imageloaded, setImageloaded] = useState(false);
  const [premiumphotos, setPremiumphotos] = useState([]);

  useEffect(() => {
    console.log(id);
    console.log(userinfo);
    //not comingthrough yet
    //or if not as long as it comes through at the bottom function its fine even though
    //it doesnt make it by this use effect

    let promoindex = Math.floor(Math.random() * allpromos.length); /*- 1*/
    console.log(promoindex);
    setPromo(allpromos[promoindex]);

    console.log(allpromos[promoindex][1].slice(7));

    if (allpromos[promoindex][1].includes("Pledge")) {
      const postsRef = collection(
        db,
        "users",
        allpromos[promoindex][1].slice(7),
        // "3qUZiqcUx1RCWPVZdVBkHgjotlp2",
        "PledgePhotos"
      );
      getDocs(query(postsRef, orderBy("Timestamp", "desc"), limit(3))).then(
        (querySnapshot) => {
          //maybe do it without the map fnction?
          let posts = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          console.log(posts);

          console.log(querySnapshot.docs.at(-1).data());
          //set it in the state
          setPremiumphotos(posts);
        }
      );
    }
  }, []);

  const PromotionLink = () => {
    if (promo[1] === "") return;
    let state;
    if (promo[1].includes("Message")) state = "UserView";
    else if (promo[1].includes("Pledge")) state = [userinfo, "Direct"];
    //how to get userinfo though
    navigate(`/${promo[1]}`, { state: state });

    console.log(id);
    console.log(userinfo);
    //have different states based on whether your going to message or pledge
    //message then state: "UserView"
    //pledge then state: [userinfo, "Direct"],
  };

  const positionstyle = () => {
    let style = promo[1]?.includes("Pledge")
      ? {
          right: 60,
          top: 28,
        }
      : {
          right: 5,
          top: 10,
        };
    return style;
  };

  return (
    <div style={{ position: "fixed" }}>
      {imageloaded && (
        <div
          style={{
            position: "absolute",
            // right: promo[1]?.includes("Pledge") ? 60 : 5,
            /*left: 30,*/
            //top: promo[1]?.includes("Pledge") ? 28 : 10,
            ...positionstyle(),
            zIndex: 20,
          }}
        >
          <LoadingAnimation duration={4} />
        </div>
      )}
      <div onClick={PromotionLink}>
        <img
          style={{ display: "block", width: "100%" }}
          src={promo[0]}
          onLoad={() => setImageloaded(true)}
        />

        {premiumphotos.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              flexWrap: premiumphotos.length > 3 && "wrap",
              //transition: "1s",
              animation: "slowappear 1s linear",
              marginRight: 2,
            }}
          >
            {premiumphotos?.map((premiumphoto, index) => (
              <div key={premiumphoto.id}>
                <img
                  draggable="false"
                  onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }}
                  id={premiumphoto.id}
                  src={premiumphoto.image}
                  /* onClick={
              premiumphoto.blur
                ? askunblur
                : () => setFullscreentarget([premiumphoto.image, "image"])
            }*/
                  style={{
                    WebkitTouchCallout: "none",
                    position: "relative",
                    bottom: 340,
                    width:
                      premiumphotos.length === 1
                        ? 240
                        : premiumphotos.length === 2
                        ? 170
                        : premiumphotos.length > 2
                        ? 130
                        : null,
                    height:
                      premiumphotos.length === 1
                        ? 220
                        : premiumphotos.length === 2
                        ? 150
                        : premiumphotos.length > 2
                        ? 130
                        : null,

                    borderRadius: premiumphotos.length === 2 ? 40 : 15,
                    marginTop: 8,
                    marginLeft: 10,
                    filter: `blur(${premiumphoto.blurlevel}px)`,

                    // pointerEvents: "none",
                    userSelect: "none",
                    WebkitUserSelect: "none",
                    msUserSelect: "none",
                    MozUserSelect: "none",
                  }}
                />

                {/*premiumphoto.blurlevel && (
                <div className="pledgeoptions">
                  <img
                    src="/Lock.svg"
                    style={{
                      zIndex: 1,
                      width: 50,
                      //marginLeft: 50,
                      // marginTop: -250,

                      position: "relative",
                      bottom:
                        premiumphotos.length === 1
                          ? 222
                          : premiumphotos.length === 2
                          ? 153
                          : premiumphotos.length > 2
                          ? 440
                          : null,
                      left:
                        premiumphotos.length === 1
                          ? 220
                          : premiumphotos.length === 2
                          ? 132
                          : premiumphotos.length > 2
                          ? 50
                          : null,
                    }}
                    id={premiumphoto.id}
                    //onClick={askunblur}
                  />
                </div>
              )*/}
              </div>
            ))}
          </div>
        )}
        {imageloaded && (
          <div
            style={{
              width: "100%",
              height: 60,
              display: "block",
              //background:"linear-gradient(#2f17e7, rgb(26, 155, 214)"
              background: "black",
            }}
          ></div>
        )}
      </div>
    </div>
  );
}

export default FullScreenPromotion;
