import { getDocs, collection, where, query, limit } from "firebase/firestore";
import { db } from ".././Firebase.js";

const haventpaid = async (id) => {
  let paidinfo;
  console.log(paidinfo);
  const dropsRef = collection(
    db,
    "users",
    "6AYdwy6Yo5QqOZ0OraOAcSbRohH2",
    "PledgeCommit"
  );
  console.log(id);

  await getDocs(
    query(
      dropsRef,
      where("PaidUser", "==", id),
      //where("Timestamp", ">", Date.now()),
      limit(1)
    )
  ).then((querySnapshot) => {
    let tempposts = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    console.log(tempposts);
    if (tempposts.length > 0) {
      paidinfo = tempposts[0];
    }
  });

  return paidinfo;
};

export { haventpaid };
