import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  addDoc,
  collection,
  getDocs,
  query,
  orderBy,
  serverTimestamp,
  deleteDoc,
  updateDoc,
  where,
  collectionGroup,
  Timestamp,
  increment,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  deleteObject,
  uploadString,
  uploadBytes,
} from "firebase/storage";
import { db, auth } from "./Firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import "./PledgeSettings.css";
import axios from "axios";
import Socket from "./Socket.js";
import Progressbar from "./Customhooks/Progressbar.js";
import { fileupload } from "./Helpers.js";

function PledgeSettings() {
  const navigate = useNavigate();

  const [userinfo, setUserinfo] = useState("");
  const [id, setId] = useState("");
  const [premiumset, setPremiumset] = useState("");
  const [price, setPrice] = useState("");
  const [periods, setPeriods] = useState("");
  const [details, setDetails] = useState("");
  const [times, setTimes] = useState("");
  const [premiumplans, setPremiumplans] = useState([]);
  const [due, setDue] = useState("");
  const [minimum, setMinimum] = useState("");
  const [title, setTitle] = useState("");
  const [contract, setContract] = useState(false);
  const [showcontract, setShowcontract] = useState("");
  const [tempphoto, setTempphoto] = useState("");
  const [realtempphoto, setRealtempphoto] = useState("");
  const [planlink, setPlanlink] = useState("");
  const [premiumphotos, setPremiumphotos] = useState([]);
  const [blurlevel, setBlurlevel] = useState("");
  const [rememberblur, setRememberblur] = useState([]);
  const [tempvideo, setTempvideo] = useState("");
  const [videostart, setVideostart] = useState(0);
  const [videoend, setVideoend] = useState(5);
  const [render, setRender] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [videostart1, setVideostart1] = useState(0 + ":" + 0 + 0);
  const [videostart2, setVideostart2] = useState(0);
  const [premiumvideos, setPremiumvideos] = useState([]);
  const [largevideo, setLargevideo] = useState(false);
  const [itemdisplay, setItemdisplay] = useState("photo");
  const [premiumlivestream, setPremiumlivestream] = useState([]);
  const [videoquery, setVideoquery] = useState(false);
  const [livestreamquery, setLivestreamquery] = useState(false);
  const [pledgedetails, setPledgedetails] = useState([]);
  const [premiumtarget, setPremiumtarget] = useState("");
  const [planquery, setPlanquery] = useState([]);
  const [progresspercent, setProgresspercent] = useState(0);

  const ref1 = useRef(null);
  const uploading = useRef(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setId(user.uid);
        const userDocumentRef = doc(db, "users", user.uid);
        getDoc(userDocumentRef).then((documentSnapshot) => {
          setUserinfo(documentSnapshot.data());
        });
        const userCollectionRef = collection(
          db,
          "users",
          user.uid,
          "PledgePlan"
        );
        await getDocs(
          query(userCollectionRef, orderBy("Timestamp", "desc"))
        ).then((querySnapshot) => {
          console.log(querySnapshot);
          setPremiumplans(
            querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }))
          );
        });

        const userCollectionRef1 = collection(
          db,
          "users",
          user.uid,
          "PledgePhotos"
        );
        getDocs(query(userCollectionRef1, orderBy("Timestamp", "desc"))).then(
          (querySnapshot) => {
            console.log(querySnapshot.size);
            if (querySnapshot.size > 0) {
              setPremiumphotos(
                querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
              );
            } else if (!querySnapshot.size) {
              setVideoquery(true);
              const userCollectionRef2 = collection(
                db,
                "users",
                user.uid,
                "PledgeVideos"
              );

              getDocs(
                query(userCollectionRef2, orderBy("Timestamp", "desc"))
              ).then((querySnapshot1) => {
                console.log(querySnapshot1.size);
                if (querySnapshot1.size > 0) {
                  setPremiumvideos(
                    querySnapshot1.docs.map((doc) => ({
                      ...doc.data(),
                      id: doc.id,
                    }))
                  );
                  setItemdisplay("videos");
                } else if (!querySnapshot1.size) {
                  setLivestreamquery(true);
                  const userCollectionRef3 = collection(
                    db,
                    "users",
                    user.uid,
                    "PledgeLivestream"
                  );

                  getDocs(
                    query(userCollectionRef3, orderBy("Timestamp", "desc"))
                  ).then((querySnapshot2) => {
                    console.log(querySnapshot2.size);
                    if (querySnapshot2.size > 0) {
                      setPremiumlivestream(
                        querySnapshot2.docs.map((doc) => ({
                          ...doc.data(),
                          id: doc.id,
                        }))
                      );
                      setItemdisplay("livestream");
                    }
                  });
                }
              });
            }
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    if (tempvideo) {
      setRerender(true);
    }
  }, [render]);

  const priceset = (e) => {
    console.log(isNaN(e.target.value));
    if (isNaN(e.target.value) === false) {
      setPrice(e.target.value);
    }
  };

  const timeset = (e) => {
    console.log(isNaN(e.target.value));
    if (isNaN(e.target.value) === false) {
      setTimes(e.target.value);
    }
  };

  const unset = () => {
    setPremiumset("");
    setDetails("");
    setTimes("");
    setPeriods("");
    setPrice("");
    setDue("");
    setMinimum("");
    setTitle("");
    setContract(false);
    setShowcontract("");
  };

  const setplan = (e) => {
    console.log(times);
    console.log(contract);
    let c = true;
    if (/^[a-zA-z]+$/.test(title) !== true && title.length > 10) {
      alert("タイトルの文字数が長すぎます");
      c = false;
    } else if (/^[a-zA-z]+$/.test(title) === true && title.length > 10) {
      alert("タイトルの文字数が長すぎます");
      c = false;
    }
    console.log(c);
    if (price < 120) {
      alert("120円以上でないといけません");
    }
    console.log(periods);

    if (
      price > 99 &&
      times > 0 &&
      title &&
      contract &&
      c &&
      !price.includes(".") &&
      !times.includes(".") &&
      ((periods < 2 && due && !due.includes(".")) ||
        (periods > 1 && minimum && !minimum.includes(".")))
    ) {
      addDoc(collection(db, `users/${id}/PledgePlan/`), {
        price: parseInt(price),
        duration:
          times +
          (periods === 0
            ? "回"
            : periods === 1
            ? "分"
            : periods === 2
            ? "週間"
            : periods === 3
            ? "ヶ月"
            : "年"),
        title: title,
        details: details,
        Timestamp: serverTimestamp(),
        subscribers: 0,
        dueby: due ? due + "週間" : "",
        minimum: minimum ? minimum : "",
        duenumber: due ? parseInt(due) : parseInt(times),
        duesymbol:
          due || periods === 2 ? "weeks" : periods === 3 ? "months" : "years",
      }).then((docRef) => {
        let lit = docRef.id;
        console.log(lit);

        setPremiumplans([
          {
            price: price,
            duration:
              times +
              (periods === 0
                ? "回"
                : periods === 1
                ? "分"
                : periods === 2
                ? "週間"
                : periods === 3
                ? "ヶ月"
                : "年"),
            title: title,
            details: details,
            subscribers: 0,
            dueby: due ? due + "weeks" : "",
            minimum: minimum ? minimum : "",
            id: lit,
          },
          ...premiumplans,
        ]);
      });
      unset();
    }
  };

  const deleteplan = async (e) => {
    let tempid = e.target.id.split(",");
    console.log(tempid);
    if (tempid[1] == 0) {
      const userDocumentRef = doc(db, "users", id, "PledgePlan", tempid[0]);
      await deleteDoc(userDocumentRef);
      let deletetarget = premiumplans.findIndex(
        (element) => tempid[0] === element.id
      );
      console.log(deletetarget);
      if (deletetarget === 0) {
        setPremiumplans([...premiumplans.slice(1)]);
      } else {
        setPremiumplans([
          ...premiumplans.slice(0, deletetarget),
          ...premiumplans.slice(deletetarget + 1),
        ]);
      }
    } else {
      alert("このプランには登録者がいるので除去できないです");
    }
  };

  const dueset = (e) => {
    if (e.target.value < 5) {
      setDue(e.target.value);
    }
  };

  const photoedit = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0].type.includes("image")) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        console.log(reader.result);
        setTempphoto(reader.result);
      };
      setRealtempphoto(e.target.files[0]);
    }
  };

  const Videoedit = (e) => {
    console.log(e.target.files[0]);
    console.log(e.target.files[0].type.includes("video"));
    if (e.target.files[0].type.includes("video")) {
      let fileurl = window.URL.createObjectURL(e.target.files[0]);
      let videotime = document.createElement("video");
      videotime.muted = true;

      console.log(videotime);
      let videotimesource = document.createElement("source");
      videotimesource.src = fileurl;
      videotime.preload = "metadata";
      videotime.appendChild(videotimesource);

      videotime.onloadedmetadata = () => {
        console.log(Math.floor(videotime.duration));
        if (
          videotime.duration > 15
          //e.target.files[0].size > 3483830
        ) {
          const reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = () => {
            console.log(reader.result);
            setTempvideo(reader.result);
          };

          console.log(e.target.files[0].size / 1024 ** 2);

          if (e.target.files[0].size / 1024 ** 2 > 100) {
            setLargevideo(true);
          }

          setTimeout(() => {
            setRender(!render);
            Socket.connect();
            Socket.on("connect", () => {
              console.log("HEEEEEEYYYYYY");
            });
            Socket.on("Uploaded", () => {
              console.log("Uploaded");
              setProgresspercent(50);
              //calculate it anticipating the initial percentage from axios as well
            });
            Socket.on("Trimmed", () => {
              console.log("Trimmed");
              setProgresspercent(60);
              //calculate it anticipating the initial percentage from axios as well
            });

            Socket.on("Finished", () => {
              console.log("Finished");
              setProgresspercent(100);
              //calculate it anticipating the initial percentage from axios as well
            });
          }, 3200);
        } else if (videotime.duration < 16) {
          alert("もう少し再生時間が長い動画にしましょう");
          e.target.value = "";
        }
      };
    } else {
      alert("動画をアップロードしましょう");
      e.target.value = "";
    }
  };

  const photoplan = () => {
    console.log(planlink);
    if (planlink) {
      let url;
      uploading.current = true;
      const storage = getStorage();
      const storageRef = ref(
        storage,
        //date.now() instead
        `images/${realtempphoto.name + new Date().getTime()}`
      );

      uploadBytes(storageRef, realtempphoto).then((uploadtask) => {
        getDownloadURL(storageRef).then((url) => {
          url = url;
          console.log(url);

          addDoc(collection(db, "users", id, "PledgePhotos"), {
            image: url,
            plan: planlink,
            Timestamp: serverTimestamp(),
            blurlevel: !blurlevel ? 3 : blurlevel,
          }).then((docRef) => {
            let lit = docRef.id;
            console.log(lit);

            setPremiumphotos([
              {
                image: url,
                plan: planlink,
                id: lit,
                blurlevel: !blurlevel ? 3 : blurlevel,
              },
              ...premiumphotos,
            ]);
          });

          const dropsRef = query(
            collectionGroup(db, "Following"),
            where("Follow_id", "==", id)
          );
          getDocs(dropsRef).then((querySnapshot) => {
            querySnapshot.docs.forEach((document) => {
              console.log(document.data().Directory);
              let Directory = document.data().Directory;
              addDoc(collection(db, `users/${Directory}/Notification/`), {
                Type: "PledgeFileUploaded",
                // Loadtype: image,
                Profile_image: userinfo.Profile_image,
                User_Name: userinfo.User_Name,
                Directory: id,
                image: url,
                /* ...{
                good: "kdk", //change this to image or video
              },*/
                Timestamp: serverTimestamp(),
              });
              updateDoc(doc(db, `users/${Directory}`), {
                Notification: increment(1),
              });
            });
          });
        });
      });

      setRememberblur(["", ...rememberblur]);

      tempdelete();
    } else {
      alert("プランを選んで下さい");
    }
  };

  const videoplan = async () => {
    if (planlink) {
      uploading.current = true;
      const config = {
        onUploadProgress: (ProgressEvent) => {
          console.log(ProgressEvent.loaded);
          console.log((ProgressEvent.loaded / ProgressEvent.total) * 10);
          setProgresspercent((ProgressEvent.loaded / ProgressEvent.total) * 10);
        },
      };
      try {
        //const instance = axios.create({ baseURL: "HEY" });
        console.log(window.location);
        console.log(window.location.host);
        console.log(`${window.location.host}:80`);
        let axiosurl =
          window.location.host === "localhost:3000"
            ? "http://localhost:80/proxyupload"
            : "https://eplise.com:9443/proxyupload";
        /* window.location.host === "localhost:3000"
            ? "http://localhost:80/proxyupload"
            : `${window.location.host}:80
            /proxyupload`;
*/
        const response = await axios.post(
          axiosurl,
          //"/proxyupload",
          //"https://eplise.com/proxyupload",
          //"http://192.168.1.5/proxyupload",
          //"http://localhost:4000/uploadvideo",//this will not work on mobile
          //"http://192.168.1.5:4000/uploadvideo",
          {
            video: tempvideo,
            videostart,
            videoend,
            largevideo,
          },
          config
        );

        console.log(response);
        console.log(response.data);

        if (response.data.success) {
          console.log(response.data);
          console.log(planlink);

          addDoc(collection(db, "users", id, "PledgeVideos"), {
            fullvideo: response.data.fullvideo.url,
            preview: response.data.trimmedvideo.url,
            fullvideo_public_id: response.data.fullvideo.public_id,
            preview_public_id: response.data.trimmedvideo.public_id,
            plan: planlink,
            Timestamp: serverTimestamp(),
            thumbnail: "",
          }).then((docRef) => {
            let lit = docRef.id;
            console.log(lit);

            setPremiumvideos([
              {
                plan: planlink,
                id: lit,
                fullvideo: response.data.fullvideo.url,
                preview: response.data.trimmedvideo.url,
                fullvideo_public_id: response.data.fullvideo.public_id,
                preview_public_id: response.data.trimmedvideo.public_id,
                plan: planlink,
                thumbnail: "",
              },
              ...premiumvideos,
            ]);
          });

          tempdelete1();
          setTimeout(() => {
            setProgresspercent(0);
          });
        }
      } catch (error) {
        console.log(error);
        alert(error);
      }
    } else {
      alert("プランを選んで下さい");
    }
  };

  const tempdelete = (e) => {
    setTempphoto("");
    setPlanlink("");
    setBlurlevel("");
    uploading.current = false;
  };

  const tempdelete1 = (e) => {
    setTempvideo("");
    setPlanlink("");
    setVideostart(0);
    setVideoend(5);
    setLargevideo(false);
    setVideostart1(0 + ":" + 0 + 0);
    uploading.current = false;
  };

  const deletephoto = (e) => {
    console.log(e.target.id);

    const userDocumentRef = doc(db, "users", id, "PledgePhotos", e.target.id);
    deleteDoc(userDocumentRef);

    const storage = getStorage();
    let deletetarget = premiumphotos.findIndex(
      (element) => e.target.id === element.id
    );
    console.log(deletetarget);
    const oldRef = ref(storage, premiumphotos[deletetarget].image);
    deleteObject(oldRef);

    if (deletetarget === 0) {
      setPremiumphotos([...premiumphotos.slice(1)]);
      setRememberblur([...rememberblur.slice(1)]);
    } else {
      setPremiumphotos([
        ...premiumphotos.slice(0, deletetarget),
        ...premiumphotos.slice(deletetarget + 1),
      ]);
      setRememberblur([
        ...rememberblur.slice(0, deletetarget),
        ...rememberblur.slice(deletetarget + 1),
      ]);
    }
  };

  const deletevideo = async (e) => {
    console.log(e.target.id);

    const userDocumentRef = doc(db, "users", id, "PledgeVideos", e.target.id);
    deleteDoc(userDocumentRef);

    let deletetarget = premiumvideos.findIndex(
      (element) => e.target.id === element.id
    );

    console.log(deletetarget);

    if (deletetarget === 0) {
      setPremiumvideos([...premiumvideos.slice(1)]);
    } else {
      setPremiumvideos([
        ...premiumvideos.slice(0, deletetarget),
        ...premiumvideos.slice(deletetarget + 1),
      ]);
    }

    console.log(premiumvideos[deletetarget].fullvideo_public_id);
    try {
      const response = await axios.delete("http://localhost:4000/deletevideo", {
        params: {
          fullvideo: premiumvideos[deletetarget].fullvideo_public_id,
          trimmedvideo: premiumvideos[deletetarget].preview_public_id,
        },
      });

      if (response.data.success) {
        console.log(response.data);
        console.log("yo");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const unblur = (e) => {
    console.log(e.target.id);
    let unblurtarget = premiumphotos.findIndex(
      (element) => e.target.id === element.id
    );
    console.log(unblurtarget);

    console.log(premiumphotos[unblurtarget].blurlevel);
    console.log(premiumphotos);

    if (unblurtarget === 0) {
      setRememberblur([premiumphotos[0].blurlevel, ...rememberblur.slice(1)]);
      setPremiumphotos([
        { ...premiumphotos[0], blurlevel: 0 },
        ...premiumphotos.slice(1),
      ]);
    } else {
      rememberblur[unblurtarget] = premiumphotos[unblurtarget].blurlevel;

      setPremiumphotos([
        ...premiumphotos.slice(0, unblurtarget),
        { ...premiumphotos[unblurtarget], blurlevel: 0 },
        ...premiumphotos.slice(unblurtarget + 1),
      ]);
    }
    console.log(rememberblur);
  };

  const bluron = (e) => {
    console.log("YO");
    console.log(e.target.id);

    let blurtarget = premiumphotos.findIndex(
      (element) => e.target.id === element.id
    );
    console.log(blurtarget);

    console.log(premiumphotos);

    if (blurtarget === 0) {
      setPremiumphotos([
        { ...premiumphotos[0], blurlevel: rememberblur[0] },
        ...premiumphotos.slice(1),
      ]);
      setRememberblur([premiumphotos[0].blurlevel, ...rememberblur.slice(1)]);
    } else {
      setPremiumphotos([
        ...premiumphotos.slice(0, blurtarget),
        {
          ...premiumphotos[blurtarget],
          blurlevel: rememberblur[blurtarget],
        },
        ...premiumphotos.slice(blurtarget + 1),
      ]);

      rememberblur[blurtarget] = premiumphotos[blurtarget].blurlevel;
    }
    console.log(rememberblur);
  };

  const durationedit = (e) => {
    console.log(e.target.value);
    if (parseInt(e.target.value) <= Math.floor(ref1.current?.duration - 5)) {
      setVideostart(e.target.value);
      setVideoend(5 + parseInt(e.target.value));
      setVideostart1(e.target.value);
      ref1.current.currentTime = e.target.value;
    } else if (
      parseInt(e.target.value) > Math.floor(ref1.current?.duration - 5) &&
      parseInt(e.target.value) <= Math.floor(ref1.current?.duration)
    ) {
      setVideostart(Math.floor(ref1.current?.duration - 5));
      setVideoend(Math.floor(ref1.current?.duration));
      setVideostart1(Math.floor(ref1.current?.duration - 5));
      ref1.current.currentTime = Math.floor(ref1.current?.duration - 5);
    }
  };

  const checkduration = (e) => {
    console.log(e.target.currentTime);
    if (Math.floor(e.target.currentTime) === videoend) {
      ref1.current.currentTime = videostart;
    }
  };

  const typevideoduration = (e) => {
    console.log(e.target.value.slice(-1));
    console.log(e.target.value);
    console.log(isNaN(e.target.value));

    /*
    let simplenumber = videostart1.splice(-3, 1);
    console.log(simplenumber);
    */

    let hours = Math.floor(Math.floor(ref1.current.duration) / 3600);
    let minutes = Math.floor((Math.floor(ref1.current.duration) % 3600) / 60);
    let seconds = Math.floor(Math.floor(ref1.current.duration) % 60);
    let colon = hours ? ":" : 0;
    console.log(hours + colon + minutes + ":" + seconds);
    let editabletime = parseInt(hours + minutes + seconds);
    console.log(editabletime);

    let inputtime = videostart1.split("");
    console.log(inputtime);
    let chime = inputtime.filter(
      (element) => !element.includes(":") && !element.includes("0")
    );
    chime = chime.join("");

    console.log(chime);
    console.log(chime === "");

    if (chime === "") {
      chime = 0;
    }
    console.log(chime);

    console.log(editabletime);
    console.log(videostart1);

    console.log(!isNaN(e.target.value.slice(-1)));
    if (
      !isNaN(e.target.value.slice(-1)) &&
      parseInt(chime) <= editabletime - 5
    ) {
      console.log("Hey");
      setVideostart1(e.target.value);
    }

    if (e.target.value !== "") {
    }
  };

  const videoload = () => {
    if (!videoquery) {
      const userCollectionRef2 = collection(db, "users", id, "PledgeVideos");

      getDocs(query(userCollectionRef2, orderBy("Timestamp", "desc"))).then(
        (querySnapshot1) => {
          console.log(querySnapshot1.size);

          setPremiumvideos(
            querySnapshot1.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }))
          );
        }
      );
      setVideoquery(true);
    }
    setItemdisplay("video");
  };

  const livestreamload = () => {
    if (!livestreamquery) {
      const userCollectionRef2 = collection(db, "users", id, "livestream");

      getDocs(query(userCollectionRef2, orderBy("Timestamp", "desc"))).then(
        (querySnapshot1) => {
          console.log(querySnapshot1.size);

          setPremiumlivestream(
            querySnapshot1.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }))
          );
        }
      );
      setLivestreamquery(true);
    }
    setItemdisplay("livestream");
  };

  const showdetail = async (e) => {
    console.log(e.currentTarget.id);
    setPremiumtarget(e.currentTarget.id);
    const userCollectionRef3 = collection(db, "users", id, "PledgeHold");
    await getDocs(
      query(
        userCollectionRef3,
        where("Pledgeid", "==", e.currentTarget.id),
        orderBy("Timestamp", "desc")
      )
    ).then((querySnapshot) => {
      console.log(querySnapshot.docs);
      setPledgedetails(
        querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
    });
  };

  const completed = async (e) => {
    updateDoc(doc(db, `users/${id}/PledgeHold/${e.target.id}`), {
      checked: e.target.checked,
    });
  };

  return (
    <div
      style={{ background: !premiumset && "linear-gradient(#c97535, #d677e1)" }}
    >
      <Link to={`/User/${id}`}>
        <img
          style={{ zIndex: 2, position: "absolute", top: 5, left: 4 }}
          src="/Arrow1.svg"
        />
      </Link>
      <div className="picline">
        <img
          src={
            userinfo?.Pledgepic ? userinfo.Pledgepic : userinfo.Profile_image
          }
          className="Pledgepic"
        />
      </div>
      {!premiumset ? (
        <>
          <div className="Premiumoption">
            <div onClick={(e) => setPremiumset(true)} className="premium">
              + 有料プランを追加
            </div>
          </div>
          <div
            className="allplans1"
            style={{
              justifyContent: premiumplans.length < 4 && "center",
              borderBottom: pledgedetails && 0,
            }}
          >
            {premiumplans.map((premiumplan) => (
              <div key={premiumplan.id}>
                <div
                  className="premiumplans"
                  style={{ paddingLeft: premiumplans.length > 1 && 10 }}
                >
                  <div
                    style={{
                      background:
                        premiumtarget === premiumplan.id &&
                        "linear-gradient(#46eb75, #578ce9)",
                    }}
                    className="premiumplans1"
                  >
                    <div className="details">
                      <div
                        onClick={showdetail}
                        id={premiumplan.id}
                        lang={
                          /^[a-zA-z]+$/.test(premiumplan.title) !== true && "ja"
                        }
                        className="details1"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flex: 1,
                          fontWeight: 200,
                          color: "gold",
                        }}
                      >
                        {premiumplan.title}
                      </div>
                      <img
                        style={{
                          width: 10,
                          zIndex: 2,
                        }}
                        src="/Xout.svg"
                        onClick={deleteplan}
                        id={[premiumplan.id, premiumplan.subscribers]}
                      />
                    </div>
                    <div
                      onClick={showdetail}
                      id={premiumplan.id}
                      className="priceduration"
                    >
                      <span style={{ color: "turquoise" }}>
                        {premiumplan.price}円
                      </span>
                      <span>/{premiumplan.duration}</span>
                    </div>
                    <div
                      onClick={showdetail}
                      id={premiumplan.id}
                      className="subscribeline"
                    >
                      <img style={{ width: 16 }} src="/Profile4.svg" />
                      <div style={{ fontSize: 9, marginLeft: 3 }}>
                        {premiumplan.subscribers}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            style={{
              borderBottom: pledgedetails && !tempvideo && "1px solid black",
            }}
          >
            {pledgedetails?.map((pledgedetail) => (
              <div key={pledgedetail.id}>
                <div
                  style={{
                    marginLeft: 5,
                    display: "flex",
                    gap: 10,
                    marginBottom: 5,
                    alignItems: "center",
                  }}
                >
                  <input
                    onClick={completed}
                    type="checkbox"
                    id={pledgedetail.id}
                    defaultChecked={pledgedetail.checked && "checked"}
                  />
                  <img
                    onClick={(e) =>
                      navigate(`/UserView/${pledgedetail.PaidUser}`)
                    }
                    style={{ width: 30 }}
                    src={pledgedetail.Profile_image}
                  />
                  {pledgedetail.Rank}
                  {pledgedetail.User_Name}
                  <div style={{ fontSize: 12 }}>
                    期限:
                    {pledgedetail.FullExpireDate.toDate().getFullYear() +
                      "/" +
                      (
                        "0" +
                        (pledgedetail.FullExpireDate.toDate().getMonth() + 1)
                      ).slice(-2) +
                      "/" +
                      (
                        "0" + pledgedetail.FullExpireDate.toDate().getDate()
                      ).slice(-2)}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {!tempphoto && !tempvideo && (
            <>
              <div
                style={{ marginTop: 5, alignItems: "center" }}
                className="Premiumoption"
              >
                <div className={itemdisplay === "photo" ? "itemdisplay" : ""}>
                  <img
                    style={{
                      width: 30,
                    }}
                    src="./Photo.svg"
                    onClick={(e) => setItemdisplay("photo")}
                  />
                </div>

                <div className={itemdisplay === "video" ? "itemdisplay" : ""}>
                  <img
                    style={{
                      width: 30,
                    }}
                    src="./Videocamera.svg"
                    onClick={videoload}
                  />
                </div>

                <div
                  className={itemdisplay === "livestream" ? "itemdisplay" : ""}
                >
                  <img
                    style={{ width: 30 }}
                    src="./livestream.svg"
                    onClick={livestreamload}
                  />
                </div>
              </div>
              <div
                style={{
                  marginLeft: 4,
                  marginTop: 7,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <label>
                  <input
                    className="upload"
                    type="file"
                    onChange={
                      itemdisplay === "photo"
                        ? photoedit
                        : itemdisplay === "video"
                        ? Videoedit
                        : null
                    }
                  />
                  <div className="uploadbutton">アップロード</div>
                </label>
              </div>

              {itemdisplay === "photo" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    flexWrap: premiumphotos.length > 3 && "wrap",
                    marginTop: 10,
                  }}
                >
                  {premiumphotos.map((premiumphoto) => (
                    <div key={premiumphoto.id}>
                      <img
                        src={premiumphoto.image}
                        style={{
                          width:
                            premiumphotos.length === 1
                              ? 240
                              : premiumphotos.length === 2
                              ? 170
                              : premiumphotos.length > 2
                              ? 110
                              : null,
                          height:
                            premiumphotos.length === 1
                              ? 220
                              : premiumphotos.length === 2
                              ? 150
                              : premiumphotos.length > 2
                              ? 90
                              : null,

                          borderRadius: premiumphotos.length === 2 ? 40 : 15,

                          filter: `blur(${premiumphoto.blurlevel}px)`,
                        }}
                      />

                      <div className="pledgeoptions">
                        <img
                          style={{
                            width: 10,
                            position: "relative",
                            bottom:
                              premiumphotos.length === 1
                                ? 225
                                : premiumphotos.length === 2
                                ? 155.5
                                : premiumphotos.length > 2
                                ? 98
                                : null,

                            left:
                              premiumphotos.length === 1
                                ? 195
                                : premiumphotos.length === 2
                                ? 120
                                : premiumphotos.length > 2
                                ? 80
                                : null,
                          }}
                          src="/Xout.svg"
                          onClick={deletephoto}
                          id={premiumphoto.id}
                        />
                        <img
                          src="/Lock.svg"
                          style={{
                            width: 13,
                            position: "relative",
                            bottom:
                              premiumphotos.length === 1
                                ? 222
                                : premiumphotos.length === 2
                                ? 153
                                : premiumphotos.length > 2
                                ? 95
                                : null,
                            left:
                              premiumphotos.length === 1
                                ? 210
                                : premiumphotos.length === 2
                                ? 132
                                : premiumphotos.length > 2
                                ? 85
                                : null,
                          }}
                          id={premiumphoto.id}
                          onClick={
                            premiumphoto.blurlevel !== 0 ? unblur : bluron
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {itemdisplay === "video" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    flexWrap: premiumphotos.length > 3 && "wrap",
                    marginTop: 10,
                  }}
                >
                  {premiumvideos.map((premiumvideo) => (
                    <div key={premiumvideo.id}>
                      <video
                        controls
                        width={
                          premiumvideos.length === 1
                            ? 240
                            : premiumvideos.length === 2
                            ? 170
                            : premiumvideos.length > 2
                            ? 110
                            : null
                        }
                        height={
                          premiumvideos.length === 1
                            ? 220
                            : premiumvideos.length === 2
                            ? 150
                            : premiumvideos.length > 2
                            ? 90
                            : null
                        }
                        style={{
                          objectFit: "cover",
                          borderRadius: premiumvideos.length === 2 ? 40 : 15,
                        }}
                        playsInline
                        autoPlay
                        muted
                        loop
                      >
                        <source src={premiumvideo.preview} />
                      </video>
                      <div className="pledgeoptions">
                        <img
                          style={{
                            width: 10,
                            position: "relative",
                            bottom:
                              premiumvideos.length === 1
                                ? 225
                                : premiumvideos.length === 2
                                ? 155.5
                                : premiumvideos.length > 2
                                ? 98
                                : null,

                            left:
                              premiumvideos.length === 1
                                ? 195
                                : premiumvideos.length === 2
                                ? 120
                                : premiumvideos.length > 2
                                ? 80
                                : null,
                          }}
                          src="/Xout.svg"
                          onClick={deletevideo}
                          id={premiumvideo.id}
                        />
                        <img
                          src="/Lock.svg"
                          style={{
                            width: 13,
                            position: "relative",
                            bottom:
                              premiumvideos.length === 1
                                ? 222
                                : premiumvideos.length === 2
                                ? 153
                                : premiumvideos.length > 2
                                ? 95
                                : null,
                            left:
                              premiumvideos.length === 1
                                ? 210
                                : premiumvideos.length === 2
                                ? 132
                                : premiumvideos.length > 2
                                ? 85
                                : null,
                          }}
                          id={premiumvideo.id}
                          onClick={(e) => console.log("yo")}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {tempphoto && (
            <>
              <div className="tempphoto">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    style={{
                      width: 200,
                      height: 180,
                      borderRadius: 40,
                      marginTop: 10,

                      filter: !blurlevel ? `blur(3px)` : `blur(${blurlevel}px)`,
                    }}
                    src={tempphoto}
                  />
                </div>

                <div
                  style={{
                    fontSize: 12,
                    color: "lightblue",
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  ぼかしレベル
                </div>

                <input
                  style={{
                    width: "100%",
                  }}
                  type="range"
                  min="3"
                  max="7"
                  value={!blurlevel ? 3 : blurlevel}
                  onChange={(e) => setBlurlevel(parseInt(e.target.value))}
                ></input>
              </div>
              <div
                style={{
                  borderTop: "1px solid black",
                  paddingTop: 10,
                  marginTop: 8,
                  fontSize: 12,
                  textAlign: "center",
                  color: "skyblue",
                }}
              >
                どのプランに加入の方のみこの写真を見れますか？
              </div>
            </>
          )}
          {tempvideo && (
            <>
              <div
                style={{
                  marginBottom: 10,
                  marginTop: pledgedetails.length > 0 && 10,
                }}
              >
                <Progressbar percent={progresspercent} />
              </div>

              <video
                controls
                width="100"
                ref={tempvideo ? ref1 : null}
                playsInline
                autoPlay
                muted
                loop
                onTimeUpdate={checkduration}
                style={{ width: "100%" }}
              >
                <source type="video/mp4" src={tempvideo} />
              </video>
              <div
                style={{ fontSize: 12, textAlign: "center", marginBottom: 4 }}
              >
                プレビュー設定
              </div>
              <div style={{ marginTop: 5 }} className="VideoSlide">
                <input
                  style={{ zIndex: 1 }}
                  type="range"
                  min="0"
                  max={ref1.current ? Math.floor(ref1.current?.duration) : "0"}
                  value={videostart}
                  onChange={durationedit}
                ></input>
                <input
                  type="range"
                  min="0"
                  max={ref1.current ? Math.floor(ref1.current?.duration) : "0"}
                  value={videoend}
                ></input>
              </div>
              <span style={{ display: "flex", justifyContent: "center" }}>
                <input
                  style={{ width: 30 }}
                  type="text"
                  value={videostart1}
                  onChange={typevideoduration}
                ></input>
                <input
                  style={{ width: 30 }}
                  type="text"
                  value={videoend}
                ></input>
              </span>
              <div
                style={{
                  borderTop: "1px solid black",
                  paddingTop: 10,
                  marginTop: 8,
                  fontSize: 13,
                }}
              >
                どのプランに加入の方のみこの動画をフルで見れますか？
              </div>
            </>
          )}

          {(tempphoto || tempvideo) && (
            <>
              <div
                className="allplans1"
                style={{
                  justifyContent: premiumplans.length < 4 && "center",
                }}
              >
                {premiumplans.map((premiumplan) => (
                  <div
                    key={premiumplan.id}
                    id={premiumplan.id}
                    onClick={(e) => setPlanlink(e.currentTarget.id)}
                  >
                    <div
                      className="premiumplans"
                      style={{
                        paddingLeft: premiumplans.length > 1 && 10,
                        marginTop: 8,
                      }}
                    >
                      <div
                        style={{
                          background:
                            planlink === premiumplan.id &&
                            "linear-gradient(#46eb75, #578ce9)",
                        }}
                        className="premiumplans1"
                      >
                        <div
                          className="details"
                          style={{ alignItems: "center" }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              width: "100%",
                              alignItems: "center",
                              color: "gold",
                            }}
                            className="details1"
                          >
                            {premiumplan.title}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="Pselect1">
                <button
                  disabled={uploading.current ? "disabled" : null}
                  onClick={
                    tempphoto ? photoplan : tempvideo ? videoplan : undefined
                  }
                >
                  〇
                </button>
                <button
                  disabled={uploading.current ? "disabled" : null}
                  onClick={
                    tempphoto ? tempdelete : tempvideo ? tempdelete1 : undefined
                  }
                >
                  X
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <div>
          <div className="plansetting">
            <div className="priceline">
              <span className="titleline">タイトル :</span>
              <input
                className="pricepoint"
                style={{ width: 80 }}
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="priceline">
              <span className="priceletter">金額 :</span>
              <input
                className="pricepoint"
                type="text"
                onChange={priceset}
                value={price}
              />
              <span
                style={{ marginLeft: 5, fontSize: 10 }}
                className="priceletter"
              >
                円
              </span>
            </div>
            <div className="durationline">
              <input type="text" onChange={timeset} value={times} />
              <span
                className={periods === 0 ? "month" : null}
                onClick={(e) => {
                  setPeriods(0);
                  setMinimum("");
                }}
                id={0}
              >
                回
              </span>
              |
              <span
                className={periods === 1 ? "month" : null}
                onClick={(e) => {
                  setPeriods(1);
                  setMinimum("");
                }}
                id={1}
              >
                分
              </span>
              |
              <span
                className={periods === 2 ? "month" : null}
                onClick={(e) => {
                  setPeriods(2);
                  setDue("");
                }}
                id={2}
              >
                週間
              </span>
              |
              <span
                className={periods === 3 ? "month" : null}
                onClick={(e) => {
                  setPeriods(3);
                  setDue("");
                }}
                id={3}
              >
                ヶ月
              </span>
              |
              <span
                className={periods === 4 ? "month" : null}
                onClick={(e) => {
                  setPeriods(4);
                  setDue("");
                }}
                id={4}
              >
                年
              </span>
            </div>
            {(periods === 0 || periods === 1) && (
              <div className="priceline" style={{ fontSize: 13 }}>
                <span style={{ color: "fuchsia" }}>
                  期限<span style={{ marginLeft: 1 }}>:</span>
                </span>
                <span style={{ marginLeft: 2, color: "deepskyblue" }}>
                  登録日から
                </span>
                <input value={due} onChange={dueset}></input>
                <span style={{ color: "deepskyblue" }}>週間後まで</span>
              </div>
            )}

            {(periods === 2 || periods === 3 || periods === 4) && (
              <div
                className="priceline"
                style={{ fontSize: 13, color: "fuchsia" }}
              >
                最低回数:
                <input
                  value={minimum}
                  onChange={(e) => setMinimum(e.target.value)}
                ></input>
              </div>
            )}
          </div>
          <div>
            <textarea
              className="drop"
              placeholder="プランの詳細 :"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              style={{ marginTop: 0, height: 100 }}
            />
          </div>
          <div className="agreeline">
            <div>
              <span>
                <input
                  onClick={(e) => setContract(!contract)}
                  style={{ height: 10 }}
                  type="checkbox"
                ></input>
              </span>
              <span className="contract">
                <span
                  style={{ textDecoration: "underline", color: "purple" }}
                  onClick={(e) => setShowcontract(true)}
                >
                  利用規約
                </span>
                に同意する
              </span>
            </div>
          </div>
          {showcontract && (
            <div className="Contractread">
              <textarea style={{ width: 250, height: 100, marginTop: 5 }}>
                第1条（適用）
                本規約は，ユーザーとeplise.comとの間の本サービスの利用に関わる一切の関係に適用されるものとします。
                eplise.comは本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下,「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
                本規約の定めが前項の個別規定の定めと矛盾する場合には，個別規定において特段の定めなき限り，個別規定の定めが優先されるものとします。
                第2条（利用登録）
                本サービスにおいては，登録希望者が本規約に同意の上，eplise.comの定める方法によって利用登録を申請し，eplise.comがこれに対する承認を登録希望者に通知することによって，利用登録が完了するものとします。
                eplise.comは，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
                利用登録の申請に際して虚偽の事項を届け出た場合
                本規約に違反したことがある者からの申請である場合
                その他，当社が利用登録を相当でないと判断した場合
                第3条（ユーザーIDおよびパスワードの管理）
                ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを管理するものとします。
                ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。eplise.comは，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
                ユーザーID及びパスワードが第三者に使用されたことによって生じた損害は，eplise.comに故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。
                第4条（売買契約）
                本サービスにおいては，ユーザーが他のユーザーから有料プランの購入の申し込みをし，これに対してeplise.comが当該申し込みを承諾した旨の通知をすることによって売買契約が成立するものとします。
                eplise.comは，ユーザーが以下のいずれかの事由に該当する場合には，当該ユーザーに事前に通知することなく，前項の売買契約を解除することができるものとします。
                ユーザーが本規約に違反した場合
                タイトルや詳細などで提示されているものと異なるプランの内容を実行された場合、
                設定された期限内までにプランの内容が実行されなっかった場合、
                その他eplise.comとユーザーの信頼関係が損なわれたと認める場合
                この様な場合には有料プランを作成したユーザーに対して他のユーザーから払われた金額もeplise.comがキャンセルすることができるものとします
                有料プランを提供しているユーザーは必ず有料サービスに登録しているユーザーとeplise.com内だけでやり取りを取ること、
                他のサイトやアプリで有料サービスの内容を提供してしまうと、ユーザーが返金のリクエストを行った場合にeplise.comがeplise.comないでのユーザーそのユーザー同士のやり取りを調査をして実際その有料プランのサービスが提供されたのかを調べます。
                その時に有料サービスが提供されてないときは無論、その有料サービスが他のサイトやアプリで提供された場合は返金対象となる
                第5条（知的財産権）
                本サービスによって提供される商品写真その他のコンテンツ（以下「コンテンツ」といいます）の著作権又はその他の知的所有権は,当社及びコンテンツ提供者などの正当な権利者に帰属し,ユーザーは,これらを無断で複製,転載,改変,その他の二次利用をすることはできません。
                第6条（禁止事項）
                ユーザーは，本サービスの利用にあたり，以下の行為をしてはならないものとします。
                法令または公序良俗に違反する行為 犯罪行為に関連する行為
                本サービスに含まれる著作権，商標権その他の知的財産権を侵害する行為
                当社のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
                本サービスによって得られた情報を商業的に利用する行為
                当社のサービスの運営を妨害するおそれのある行為
                不正アクセスをし，またはこれを試みる行為
                他のユーザーに関する個人情報等を収集または蓄積する行為
                他のユーザーに成りすます行為
                eplise.comのサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
                その他，eplise.comが不適切と判断する行為
                第7条（本サービスの提供の停止等）
                eplise.comは，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
                コンピュータまたは通信回線等が事故により停止した場合
                その他，eplise.comが本サービスの提供が困難と判断した場合
                eplise.comは，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害について，理由を問わず一切の責任を負わないものとします。
                第8条（利用制限および登録抹消）
                eplise.comは，以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。
                本規約のいずれかの条項に違反した場合
                登録事項に虚偽の事実があることが判明した場合
                決済手段として当該ユーザーが届け出たクレジットカードが利用停止となった場合
                料金等の支払債務の不履行があった場合
                eplise.comからの連絡に対し，一定期間返答がない場合
                本サービスについて，最終の利用から一定期間利用がない場合
                その他，eplise.comが本サービスの利用を適当でないと判断した場合
                eplise.comは，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。
                第9条（保証の否認および免責事項）
                eplse.comは,本サービスに事実上または法律上の瑕疵（安全性,信頼性,正確性,完全性,有効性,特定の目的への適合性,セキュリティなどに関する欠陥,エラーやバグ,権利侵害などを含みます。）がないことを保証するものではありません。
                eplse.comは,本サービスによってユーザーに生じたあらゆる損害について,一切の責任を負いません。ただし,本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合,この免責規定は適用されませんが,この場合であっても,eplse.comは,eplse.comの過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（eplse.comまたはユーザーが損害発生につき予見し,または予見し得た場合を含みます。）について一切の責任を負いません。
                eplse.comは，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
                第10条（サービス内容の変更等）
                eplse.comは，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
                第11条（利用規約の変更）
                eplse.comは，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
                第12条（個人情報の取扱い）
                eplse.comは，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。
                第13条（通知または連絡）
                ユーザーとeplse.comとの間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,eplse.comが別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
                第14条（権利義務の譲渡の禁止）
                ユーザーは，eplse.comの書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
                第15条（準拠法・裁判管轄）
                本規約の解釈にあたっては，日本法を準拠法とします。なお，本サービスに関しては，国際物品売買契約に関する国際連合条約の適用を排除するものとします。
                本サービスに関して紛争が生じた場合には，eplse.comの本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。"
                第15条
                未成年のアダルトコンテンツは法律で禁じられてます。もし未成年でしたらアダルトコンテンツのプランの作成をお控えください。
                第16条
                未成年でしたらプランの作成は親権者の方を通して行い下さい。
                第17条
                アカウントに貯まった金額の引き出しは1万円から可能になります
                第18条
                サイトのルールに違反しているなどその他の理由でアカウントが削除をされる場合があります
                第19条
                もしアカウントが削除された場合にアカウントに残ったそのユーザーの保有残高は払われない場合がありますので注意してください。
              </textarea>
            </div>
          )}
          <div className="Pselect1">
            <button onClick={setplan}>〇</button>
            <button onClick={unset}>X</button>
          </div>
        </div>
      )}
    </div>
  );
}
export default PledgeSettings;
