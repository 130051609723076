import React from "react";
import LoadingAnimation from "./LoadingAnimation.js";
import "./PayDemand.css";
import { useNavigate } from "react-router-dom";
function PayDemand({ demandinfo, close }) {
  const navigate = useNavigate();

  return (
    <div
      className="paydemand"
      style={{
        height: "100%",
        border: "1px solid aqua",
      }}
    >
      <div
        style={{
          position: "absolute",

          zIndex: 20,
          right: 6,
          top: 3,
        }}
      >
        <LoadingAnimation duration={12} close={close} />
      </div>
      <div
        onClick={() =>
          navigate(`/Pledge/${demandinfo.Payingto}`, {
            //state: [userinfo, "Direct"],maybe if we have zustand pass in the userinfo not realyy
            //worth querying it without zustand
          })
        }
        className="picline"
      >
        <img src={demandinfo?.PlanUserImage} className="Pledgepic" />
      </div>
      <div
        className="suggestiontext"
        style={{ textAlign: "center", marginTop: 10 }}
      >
        まだ{demandinfo?.PlanUserName}さんへのお支払いが完了していません！
      </div>
      <div style={{ textAlign: "center" }}>
        <span style={{ color: "aqua" }}>{demandinfo.FinalPrice}</span>
        <span style={{ marginLeft: 4, fontSize: 12 }}>円</span>
      </div>
      <div style={{ textAlign: "center" }}>
        <span style={{ fontSize: 10, color: "white" }}>
          {
            //this is going to be a componenet to plug in?
            //better paypay payment method?
          }
          このPayPayIDに提示の額を送り下さい :
        </span>
        <span style={{ color: "aqua", marginLeft: 4 }}>eplise</span>
      </div>
      {
        //maybe clickable to go to their page
      }
      {
        //maybe use qr code or link as a method
      }
      {
        //maybe query 3 pledge photos?
        //and display it
      }
    </div>
  );
}
export default PayDemand;
