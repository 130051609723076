//post.id get rid
//make it so you can return however many specified number of ids
//querry using these ids
let finalarray = [];
const getpledgeids = () => {
  let idlists = [
    // post.id !== "1MNrwvjBwEbB7NmJR05bLLTNueq1" &&
    "b3wZEloxcxRwAZ9fsYYzhjRaQBF3",
    "67Ts6SSTpcZyHiu7YzxWmhDQGET2",
    "BOhuxP952PTB48Er58AnNFWV0rp1",
    "RKOselx1VsUvvx2u6UxtyvB1kdy1",
    // post.id !== "nwiA8YNllRNei0CiBrepxwF5N443" &&
    "jjZZxnuk1Nc0lYmBWqHzRQM9sTv2",
    "jQDLCTZyYVWaGVXe9L5FBceuxKl2",
    "V5ZiV6R1eOfvc1cmXCb3pxaMGXl1",
    "csLdLwL4WkQpsiIbrXPpRJpbrSr2",
    "V6SZs6Lo16awApZ21csqPWyDof03",
    "mR1z1bgWE5SpEgAQn6KzKL5i6mC2",
    "hCJ7PGRXdyaECj9H87FKQ4JNA8p2",
    "mwpxioxmk4fKnALLAiAlO6e5CTF3",
    "FPKUV8lx4GOJE3oSh2ZDsxGASMT2",
    "waErcsXQCVfrhuDS1xoutrEFN8v2",
    "fQy057eBAaOFR1KTtz0YzGgjKTh1",
    "u5yQav7Bf5UNorVxoKv6JZKzU6i1",
    "tyVcVtukMuSqw7fC7qyszaBNwV83",
    "l0NLc8pyr2XqrQEHyRMJttit4R52",
    "NkDFoKgVP5TC0LKZaRwm6yaSYt73",
    "3qUZiqcUx1RCWPVZdVBkHgjotlp2",
    "a73FSHL4YwWjxPh2yq9AouQLgWT2",
    "cckYv63upCaZHCDOJm032n9rQGN2",
    "P51xCtJBbZXKD3rr4hgfxWJZjF13",
    "wzS1qwaccQeM8qsCDvcTHKzYFw63",
    "LQ1ynW7ov1dgdWTzfwV7cbvQn0H2",
    "mTWvd0qmTbODFeqt0F1JbPFpI3d2",
    "7ByY0I2F0dd6JCIMZk3VDRruKCQ2",
    "iZMFqVk08ogUG8NMuw9hqc553eB3",
  ];

  for (let i = 0; i < 2 /*how many id are we returning*/; i++) {
    console.log(i);
    let index = Math.floor(Math.random() * idlists.length);
    finalarray.push(idlists[index]);
    idlists.splice(index, 1);
  }
  return finalarray;
};
//runit();

//return finalarray;
export { getpledgeids /*finalarray */ };
