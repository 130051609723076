import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  addDoc,
  collection,
  query,
  orderBy,
  serverTimestamp,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db, auth } from "./Firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import Paymentform from "./Paymentform";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "./PledgeSubscribe.css";

function PledgeSubscribe() {
  const { userid } = useParams();
  const location = useLocation();
  const Public_Key =
    "pk_test_51NMGCcBxT13QwRtaWZfi8ym0fzXzUxcQtWOYqcl0suDSVqm4LnvKiK6aw1eARISZ7zvUcokI2569kCB5BhDtG3Ew00UCu5WSMR";
  const stripePromise = loadStripe(Public_Key);
  const navigate = useNavigate();
  const finished = (answer) => {
    console.log(answer);
    setSuccess(answer);
  };

  const [paymethod, setPaymethod] = useState("");
  const [success, setSuccess] = useState(false);
  const [subscribeddata, setSubscribeddata] = useState("");
  const [qualify, setQualify] = useState(false);
  const [investigating, setInvestigating] = useState(false);
  const [waitingkonbini, setWaitingkonbini] = useState(false);
  const [konbiniinfo, setKonbiniinfo] = useState("");
  const [finalprice, setFinalprice] = useState("");

  useEffect(() => {
    console.log(userid);
    console.log(location.state);
    console.log(location.state[0]);
    console.log(location.state[1]);

    const userCollectionRef1 = collection(db, "users", userid, "PledgeHold");

    getDocs(userCollectionRef1).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let a = posts.filter(
        (post) =>
          post.PaidUser === location.state[1].id &&
          post.Pledgeid === location.state[0].id
      );

      if (a.length === 1) {
        console.log(a.length);
        console.log(...a);
        console.log(a[0].Price);
        setSubscribeddata(...a);
        /*  if (a[0].investigation) {
          setInvestigating(true);
        }
      } else {
        const userCollectionRef2 = collection(
          db,
          "users",
          location.state[1].id,
          "OutstandingPay"
        );

        getDocs(userCollectionRef2).then((querySnapshot) => {
          let posts1 = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          console.log(posts1);
          if (posts1.length > 0) {
            console.log(posts1);
            let b = posts1.filter(
              (post) => post.Pledgeid === location.state[0].id
            );
            console.log(b);
            if (b.length === 1) {
              setWaitingkonbini(true);
              setKonbiniinfo(...b);
              console.log(b[0].Konbini_due);
              console.log(new Date(b[0].Konbini_due * 1000));
            }
          }
        });*/
      }
    });
  }, []);

  const refundquestions = () => {
    console.log(new Date().getTime());
    console.log(subscribeddata);
    console.log(subscribeddata.ExpireNumber);
    console.log(subscribeddata.FullExpireDate.seconds * 1000);

    if (new Date().getTime() > subscribeddata.ExpireNumber) {
      setQualify(true);
    } else {
      alert(
        "返金のリクエストが出来る期間は" +
          new Date(subscribeddata.ExpireNumber) +
          "から" +
          new Date(subscribeddata.ExpireNumber + 86400000 * 3) +
          "の間です"
      );
    }
  };

  const refundinvestigate = (e) => {
    updateDoc(doc(db, `users/${userid}/PledgeHold/${subscribeddata.id}`), {
      investigation: e.target.id,
    });

    setDoc(
      doc(
        db,
        "users",
        "6AYdwy6Yo5QqOZ0OraOAcSbRohH2",
        "investigation",
        subscribeddata.id
      ),
      {
        investigation: e.target.id,
        requester: location.state[1].id,
        investigated: userid,
        type: "PledgePlan/PledgeHold",
        PledgeHoldid: subscribeddata.id,
      }
    );
    setInvestigating(true);

    setTimeout(() => {
      navigate(`/Pledge/${userid}`, {
        state: location.state[1].id,
      });
    }, 2000);
  };

  const taxes = (e) => {
    console.log(e.target.id);
    setPaymethod(e.target.id);
    let price = parseInt(location.state[0].price);
    let totalprice = Math.round(price * 0.136 + price);
    setFinalprice(totalprice);
    /*if (e.target.id === "card" || e.target.id === "konbini" || "PayPay") {
      setFinalprice(Math.round(price * 0.136 + price));
    } else if (e.target.id === "jp_bank-transfer") {
      setFinalprice(Math.round(price * 0.136 + price));
    }*/

    const Paycommit = (
      userinfo,
      paymethod,
      today,
      duenumber,
      pledgeid,
      duesymbol,
      Payingto,
      PlanUserinfo
    ) => {
      console.log("djdjk");
      console.log(location.state[0].duenumber);
      const daysinmonth = () => {
        let dates = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 30];
        console.log(duenumber);
        let month = today.getMonth();
        let b = 0;
        let extraround = 0;
        for (let step = 0; step < duenumber; step++) {
          let index = month + step - extraround;
          b += dates[index];
          if (index === 11) {
            month = 0;
            extraround = step;
          }
        }

        console.log(b);
        return b;
      };

      //test with multiple months, //use まさ and shiz
      //put and import function smoothly
      ///perfect this paycommit and daysinmonth functions imported or orgainze or whatever
      //fix the rank up animation code in user page
      //the pledge photo and plan link flicker purple to green isnt working still in mobile
      /////////////////////////////////////////////////////////
      //notification to the girl that someone pledged to them
      //notification to the guy that you have joined the girls pledge since payment went through
      //make sure if someone is pledged to you it shows in the messages without the pledge filter on
      //on the default filters
      //be able to click on the pledge notification and display the right shiz
      //back works as well back to connect? or notification? you choose
      //each time new pledge photo is uploaded send notificatino to people following you that new photo is uploaeded
      //be able to click onit nd takes them to the page
      //make sure arrowback work from the pledge page
      //even though we can fullscreenimages, cant zoom in
      //add other girls pledge shiz at the bottom of pledge as suggestions
      //combine pledge notifications if multiple users pledges to the same plan
      //maybe a button function to do it ALL ( like the yooo button that sends message)
      //upon payment confirm just plug it id of the payment and it gets the doc, and set the pledge hold and pledge notification
      //(maybe even the payment ) to her
      //(maybe even delete the particular payintent)
      //fix in sign up make +81 a different section so people dont delete it and combine it together when you press it
      //when sent to pledgesettings of thats not yours from url, than navigate to pledgesubscribe page (make sure all arrow back is abled with no error)
      //add other girls pledge suggestions in pledge page
      //maybe for pledgesettings url have id :userid thus shuould be redirected to that pledgesubscribe if it aint your own id
      //make sure non eplise user can come to pledge page as well and if tried anything, ask to sign up
      //if sent text in messages in messageview is a https or .com make it a clickable link, dont need copy and paste
      //back to fullscreenvideo muting when coming in from miniplayer

      let bro = daysinmonth();
      console.log(bro);

      let a =
        duesymbol === "months"
          ? daysinmonth()
          : duenumber * (duesymbol === "weeks" ? 7 : 365);

      console.log(a);

      let totaldays = today.getTime() + 86400000 * a;

      let totaldays1 =
        today.getTime() +
        86400000 *
          (duesymbol === "months"
            ? daysinmonth()
            : duenumber * (duesymbol === "weeks" ? 7 : 365));

      console.log(totaldays);
      console.log(totaldays1);

      addDoc(
        collection(db, "users", "6AYdwy6Yo5QqOZ0OraOAcSbRohH2", "PledgeCommit"),
        {
          Pledgeid: pledgeid,
          PaidUser: userinfo.id,
          Payingto: Payingto,
          Price: price,
          FinalPrice: totalprice,
          ExpireNumber: totaldays,
          FullExpireDate: new Date(totaldays),
          Timestamp: serverTimestamp(),
          Paymethod: paymethod,
          Profile_image: userinfo.Profile_image,
          User_Name: userinfo.User_Name,
          Rank: userinfo.Rank,
          PlanUserName: PlanUserinfo.User_Name,
          PlanUserImage: PlanUserinfo.Profile_image,
        }
      );
    };
    if (e.target.id === "PayPay") {
      let today = new Date();
      Paycommit(
        location.state[1],
        e.target.id,
        today,
        location.state[0].duenumber,
        location.state[0].id,
        location.state[0].duesymbol,
        userid,
        location.state[2]
      );
    }
  };

  return (
    <div style={{ background: "linear-gradient(#d946e4, #83d7ec)" }}>
      <img
        style={{ zIndex: 2, marginTop: 5, marginLeft: 4 }}
        src="/Arrow1.svg"
        onClick={(e) =>
          navigate(`/Pledge/${userid}`, { state: [location.state[1]] })
        }
      />

      <div>
        {!subscribeddata ? (
          <></>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src="/Subscribed.svg" style={{ width: 20 }} />
              加入済み
            </div>
          </>
        )}
        <div className="subscribetitle">{location.state[0]?.title}</div>
        <div className="subscribeprice">
          <span style={{ borderBottom: "1px solid black", paddingBottom: 2 }}>
            <span style={{ color: "CornflowerBlue" }}>金額 | </span>
            <span style={{ color: "purple", fontStyle: "italic" }}>
              {location.state[0]?.price}円
            </span>
          </span>
        </div>
        <div style={{ textAlign: "center" }}>
          <span style={{ color: "BlueViolet" }}> 期間 | </span>
          <span style={{ color: "blue" }}>{location.state[0].duration}</span>
        </div>

        <div
          style={{
            marginTop: 5,
            marginBottom: 10,
            fontSize: 12,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "80%", textAlign: "center" }}>
            {location.state[0].details}
          </div>
        </div>
      </div>
      {!subscribeddata ? (
        <>
          {!waitingkonbini ? (
            <>
              {!success ? (
                <>
                  <div
                    style={{
                      border: "1px solid black",
                      borderRadius: 20,
                      marginTop: 10,
                    }}
                  >
                    <div style={{ textAlign: "center" }}>お支払い方法：</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                        height: 80,
                        width: "100%",
                        flex: "0 0 33%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          background:
                            paymethod === "card" &&
                            "linear-gradient(#c4d1d2, #42b1e0)",
                          borderRadius: 30,
                          width: paymethod === "card" && 115,
                        }}
                      >
                        <div
                          id={"card"}
                          onClick={taxes}
                          style={{ fontSize: 5 }}
                        >
                          カード決済
                        </div>
                        <img
                          id={"card"}
                          onClick={taxes}
                          style={{ width: 90, marginBottom: 5 }}
                          src="/credit_card.svg"
                        />
                      </div>
                      |
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",

                          background:
                            paymethod === "konbini" &&
                            "linear-gradient(#c4d1d2, #42b1e0)",
                          borderRadius: paymethod === "konbini" && 30,
                          width: paymethod === "konbini" && 95,
                        }}
                      >
                        <div
                          id={"konbini"}
                          onClick={taxes}
                          style={{ fontSize: 5 }}
                        >
                          コンビニ決済
                        </div>
                        <img
                          id={"konbini"}
                          onClick={taxes}
                          style={{ width: 70, marginBottom: 5 }}
                          src="/konbini.svg"
                        />
                      </div>
                      |
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          background:
                            paymethod === "jp_bank-transfer" &&
                            "linear-gradient(#c4d1d2, #42b1e0)",
                          borderRadius: paymethod === "jp_bank-transfer" && 30,
                          width: paymethod === "jp_bank-transfer" && 85,
                        }}
                      >
                        <div
                          id={"jp_bank-transfer"}
                          onClick={taxes}
                          style={{ fontSize: 5 }}
                        >
                          口座振り込み
                        </div>
                        <img
                          id={"jp_bank-transfer"}
                          onClick={taxes}
                          style={{ width: 70, marginBottom: 5 }}
                          src="/bank.svg"
                        />
                      </div>
                      |
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          background:
                            paymethod === "PayPay" &&
                            "linear-gradient(#c4d1d2, #42b1e0)",
                          borderRadius: paymethod === "PayPay" && 30,
                          width: paymethod === "PayPay" && 80,
                        }}
                      >
                        <div
                          id={"PayPay"}
                          onClick={taxes}
                          style={{
                            fontSize: 5,
                            marginBottom: 2,
                          }}
                        >
                          PayPay
                        </div>
                        <img
                          id={"PayPay"}
                          onClick={taxes}
                          style={{ width: 55, marginBottom: 5 }}
                          src="/PayPay.svg"
                        />
                      </div>
                    </div>
                  </div>

                  {paymethod && (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: 5,
                          marginBottom: -4,
                          fontSize: 9,
                        }}
                      >
                        手数料 :￥
                        <span>
                          {paymethod === "card" ||
                          paymethod === "konbini" ||
                          paymethod === "PayPay"
                            ? Math.round(
                                parseInt(location.state[0].price) * 0.036
                              )
                            : paymethod === "jp_bank-transfer"
                            ? Math.round(
                                parseInt(location.state[0].price) * 0.015
                              )
                            : null}
                        </span>
                      </div>
                      <div style={{ textAlign: "center", fontSize: 10 }}>+</div>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 9,
                          marginBottom: -4,
                        }}
                      >
                        消費税 :￥
                        <span>
                          {Math.round(parseInt(location.state[0].price) * 0.1)}
                        </span>
                      </div>
                      <div style={{ textAlign: "center", fontSize: 10 }}>+</div>
                      <div
                        style={{
                          textAlign: "center",
                          marginBottom: 4,
                          fontSize: 9,
                        }}
                      >
                        プラン費 : ￥{location.state[0].price}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          marginBottom: 4,
                        }}
                      >
                        <div style={{ color: "rebeccapurple" }}>
                          ご請求額 :￥{finalprice}
                        </div>
                      </div>
                      <Elements stripe={stripePromise}>
                        <Paymentform
                          Paymethod={paymethod}
                          FinalPrice={finalprice}
                          Price={location.state[0].price}
                          Finished={finished}
                          Userid={userid}
                          Pledgeid={location.state[0].id}
                          ExpireNumber={location.state[0].duenumber}
                          Symbol={location.state[0].duesymbol}
                          Userinfo={location.state[1]}
                        />
                      </Elements>
                    </>
                  )}
                  <Link to={`/CreditLaws`} style={{ textDecoration: "none" }}>
                    <div
                      style={{
                        color: "aqua",

                        fontSize: 10,
                        marginTop: paymethod ? 20 : 5,
                        textAlign: "center",
                      }}
                    >
                      .特定商取引法に基づく表記.
                    </div>
                  </Link>
                </>
              ) : (
                (paymethod === "card" && <div>お支払い完了</div>) ||
                (paymethod === "konbini" && <div>コンビニ支払い受付完了</div>)
              )}
            </>
          ) : (
            <>
              <div>コンビニ支払いのご案内 </div>
              <div>
                有効期限:
                {new Date(konbiniinfo.Konbini_due * 1000).getFullYear()}年/
                {new Date(konbiniinfo.Konbini_due * 1000).getMonth() + 1}
                月/
                {new Date(konbiniinfo.Konbini_due * 1000).getDate()}日 金額:
                {konbiniinfo.Price}円 第1番号:{konbiniinfo.Payment_code}
                第2番号:{konbiniinfo.Phone}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {!investigating ? (
            <>
              {!qualify ? (
                <div>
                  <button onClick={refundquestions}>返金のリクエスト？</button>
                </div>
              ) : (
                <>
                  <div>
                    <button
                      id={"受け取った商品が約束されたものと違う"}
                      onClick={refundinvestigate}
                    >
                      受け取った商品が約束されたものと違う
                    </button>
                    <button
                      id={"商品をまだ受け取っていない"}
                      onClick={refundinvestigate}
                    >
                      商品をまだ受け取っていない
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <div>返金リクエストを調査中です...</div>
          )}
        </>
      )}
    </div>
  );
}

export default PledgeSubscribe;
