import {
  getDocs,
  addDoc,
  collection,
  query,
  serverTimestamp,
  updateDoc,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../Firebase.js";

const PledgeHoldCheck = async (userid, PaidUser) => {
  console.log(userid);
  console.log(PaidUser);
  const postsRef = collection(db, "users", userid, "PledgeHold");
  let Expired = [];
  let NotExpired = [];
  await getDocs(query(postsRef, where("PaidUser", "==", PaidUser))).then(
    (querySnapshot) => {
      //2 arrays

      // for testing add one that is expired and other one that is not expired
      //for testing any errors if youre not pledged to the person
      //action for when its only expired
      //action for when its only Not expired
      //action for when its Both expired and Not expired
      //action for when its only Multiple expired
      //action for when its only Multiple Not expired
      //action for when its Both Multiple expired and Multiple Not expired
      //prolly getdocs and use where of the user pledgehold and paid User( could be multiple ) ANNNND also where the fullexpiredate is less than date.now()
      //use forloop to delete doc
      //make sure the images are not shown until the check for pledge hold is valid or nah
      //pledgehold unsubscribe when time comes in userview page, and pledge page BE CAREFUL on pledge page that you await to display photo until you know FOR SURE The Pledge HOLD IS not expired or you dont even have a pldege check
      //so you dont expose unbluered image if not pldeged or pledgehold expired
      //also pledge plan subscribe number decrement this has to be forloop as well for the number of plans the dude is subscribed to
      //incorporate this with pledge subscribe will be lil tricky
      //userview and pledge subscribe

      console.log(querySnapshot);
      let today = new Date();
      let today1 = new Date().getTime();
      let today2 = Date.now() / 1000;
      console.log(today);
      console.log(today1);
      console.log(today2);

      querySnapshot.docs.forEach((doc) =>
        ////////////////////do this correctly now
        doc.data()?.FullExpireDate.seconds <= today2
          ? Expired.push({ ...doc.data(), id: doc.id })
          : NotExpired.push({ ...doc.data(), id: doc.id })
      );
      // console.log(querySnapshot.docs.data());
      console.log(querySnapshot.docs.length);
      console.log(Expired);
      console.log(NotExpired);
    }
  );

  const PledgeHoldExpired = async (pledgeholdlist) => {
    //this function is separate because you can run this at the top or towards the bottom
    //its movable depending on you are in userview or pldege subscibe page
    //maybe keep it separate it or not who knows
    for (let i = 0; i < pledgeholdlist.length; i++) {
      //each that is expired delete doc
      //if (posts[i].Redrop_id === unredropid[0]) unredroptarget = posts[i].id;
    }
  };
  return [Expired, NotExpired];
};
export { PledgeHoldCheck };
