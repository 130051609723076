import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  orderBy,
  deleteDoc,
  limit,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../Firebase.js";
import "./Pledge.css";
import FullScreenImage from "../Customhooks/FullScreenImage.js";
import FullScreenVideo from "../Customhooks/FullScreenVideo.js";
import { PledgeHoldCheck } from "../Customhooks/PledgeHoldCheck.js";
//import { finalarray } from "./PledgeSuggestions.js";
import { getpledgeids } from "./PledgeSuggestions.js";

function Pledge() {
  const { userid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const ref1 = useRef(null);

  const [viewinfo, setViewinfo] = useState("");
  const [userinfo, setUserinfo] = useState("");
  const [premiumplans, setPremiumplans] = useState([]);
  const [premiumphotos, setPremiumphotos] = useState([]);
  const [unblur, setUnblur] = useState("");
  const [fullscreentarget, setFullscreentarget] = useState([]);
  const [pledgesuggestions, setPledgesuggestions] = useState({});

  const doit = () => {
    onAuthStateChanged(auth, async (user) => {
      let value;
      console.log(user.uid);
      if (user) {
        const userDocumentRef = doc(db, "users", user.uid);
        await getDoc(userDocumentRef).then((documentSnapshot) => {
          console.log(documentSnapshot.data());
          console.log(documentSnapshot.id);
          value = [documentSnapshot.data(), "Direct"];
          // return [documentSnapshot.data(), "Direct"];
        });
      }
      return value;
    });
  };
  const [locationornot, setLocationornot] = useState(
    () => (location.state ? location.state : [/*{ id: 0 }*/ null, null])

    //[]
  );

  const getpledgesuggestions = async (/*getpledgeids*/) => {
    //if pledge ids the same as you or the girl youre looking at prlly filter ahead of time
    //going back from someone elses pledge suggestion, make sure you are able to go back to this persons pledge page
    //what if their pledge plan isnt there, link to their messages?
    let pldgesuggestioninfo = [];
    let pledgephotos = [];
    let promises = [];
    let promise = [];
    let promise1 = [];

    const pledgeids = getpledgeids();
    //for (let i = 0; i < 2 /*how many id are we returning*/; i++) {}

    console.log(pledgeids);

    //we are getting repeated ids as well so splice not working?
    // await new Promise(
    /*async*/ //(resolve) => {
    for (const id of pledgeids) {
      let userDocumentRef1 = doc(db, "users", id);

      promise.push(
        getDoc(userDocumentRef1).then((documentSnapshot) => {
          console.log(documentSnapshot.data());
          // pldgesuggestioninfo.push(
          return {
            ...documentSnapshot.data(),
            id: documentSnapshot.id,
          };

          //);
          // console.log(pldgesuggestioninfo);
          //why they getdoc is getting called 6 times instead of 4?
          //this slice is for non production since useeffect runs multiple times
          //same chick sometimes gets in even though we spliced the duplicated shiz
          //make sure the back works going to other suggested page back to this main chick
          /* setPledgesuggestions([
              pldgesuggestioninfo.slice(0, 2),
              pledgephotos.slice(0, 2),
            ]);*/
        })
      );

      let userCollectionRef1 = collection(db, "users", id, "PledgePhotos");

      promise1.push(
        getDocs(
          query(userCollectionRef1, orderBy("Timestamp", "desc"), limit(2))
        ).then((querySnapshot) => {
          //let photos =
          // pledgephotos.push(
          return querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            /*blured.forEach((blur) =>
              doc.data().plan === blur.Pledgeid &&
              blur.PaidUser === locationornot[0]?.id
                ? true
                : false
            ),
*/
            blur: true, //foreach here to avoid needless loops
          }));

          // );
          // return photos;
          /* setPledgesuggestions([
               pldgesuggestioninfo.slice(0, 2),
               pledgephotos.slice(0, 2),
             ]);*/
        })
      );
    }
    // resolve();
    // }
    //);

    // setPledgesuggestions([pldgesuggestioninfo, pledgephotos]);
    await Promise.all([promise[0], promise[1], promise1[0], promise1[1]]).then(
      (values) => {
        console.log(values, "DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD");

        // setPledgesuggestions([values[0].slice(0, 2), values[1].slice(0, 2)]);
        setPledgesuggestions([
          [values[0], values[1]],
          [values[2], values[3]],
        ]);
      }
    );
    // return [pldgesuggestioninfo, pledgephotos];
  };

  useEffect(() => {
    console.log(location.state);
    console.log(userid);

    // console.log(getpledgesuggestions(), "KKKKKKKKKKKK");
    const pledgesuggestionplugin = getpledgesuggestions();
    /* setPledgesuggestions([
      pledgesuggestionplugin[0],
      pledgesuggestionplugin[1],
    ]);
*/
    const userDocumentRef = doc(db, "users", userid);
    getDoc(userDocumentRef).then((documentSnapshot) => {
      setViewinfo(documentSnapshot.data());
    });

    const userCollectionRef = collection(db, "users", userid, "PledgePlan");
    getDocs(query(userCollectionRef, orderBy("Timestamp", "desc"))).then(
      (querySnapshot) => {
        setPremiumplans(
          querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
      }
    );
    //bundles run each time on render look up? like imports and shiz from other files that youre not even on
    //look up how to set initial state using fetch/query get request
    //set the getdoc in the initial usestate ? or nah
    //handle if you come from url as a signed up user useffect, pledgesubscribe GO, back, one of the image been paid and should be unblured
    //handle if you come from url as a TOTAL guest useffect, pledgesubscribe GO, back
    // f4 search location key word to fix all needed problem

    //why so many rerendrrs? when coming back from pledgesubscribe
    //is Direct correct when no location.state
    //maybe instead of direct, use Guest to indicate usercame from url? AND is not
    // signed in or havent signed up
    // thus
    //they get sent to sign up page

    //pledgeholdheck locationornot is null so this doesnt work off the bat
    //when coming back pledgeholdcheck dont work or nah?

    //if you dont come directly to pledge subscribe ( if you just jump to url ) than it errors out since
    //location.state is not there // Prolly make an if statement if location.state isnt there use get doc to get all the data you need locatio state and shiz,
    //and save it in a initial state or variable state()=>  or in a use effect
    ////////////////////////////////////////////////
    //how to run function within mapped
    //how to run forloop within mapped
    //be able to pay even as a guest?
    //wavy lines in css or inkscape to use in pledge and pledge girls suggestions
    //wavy lines in css or inkscape to use in user page to separate from coneect
    //anyways to enable phone without revealing phone number of other person

    console.log(locationornot);
    let nolocation;

    const Pledgeaction = async () => {
      await new Promise((resolve) => {
        if (location.state) {
          console.log(locationornot[0]?.id);
          nolocation = locationornot[0]?.id;
          resolve();
        } /* (!location.state)*/ else {
          onAuthStateChanged(
            auth,
            /*async*/ (user) => {
              console.log(user.uid);
              if (user) {
                const userDocumentRef = doc(db, "users", user.uid);
                /*await*/ getDoc(userDocumentRef).then((documentSnapshot) => {
                  console.log(documentSnapshot.id);
                  console.log(documentSnapshot.data());
                  nolocation = documentSnapshot.id;
                  setLocationornot([
                    { ...documentSnapshot.data(), id: documentSnapshot.id },
                    "Direct",
                  ]);
                  resolve();
                });
              }
            }
          );
        }
      });

      console.log(locationornot);

      let promise;
      let promise1;
      let photos;
      let blured;
      let expired;
      console.log(nolocation);
      //this pledgeholdcheckneeds to wait for the authstate changed to get the nolocation
      promise = PledgeHoldCheck(
        userid,
        nolocation //location.state[0].id  locationornot[0]?.id
      );

      const userCollectionRef1 = collection(
        db,
        "users",
        userid,
        "PledgePhotos"
      );

      promise1 = getDocs(
        query(userCollectionRef1, orderBy("Timestamp", "desc"))
      ).then((querySnapshot) => {
        let photos = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          /*blured.forEach((blur) =>
              doc.data().plan === blur.Pledgeid &&
              blur.PaidUser === locationornot[0]?.id
                ? true
                : false
            ),*/

          blur: true, //foreach here to avoid needless loops
        }));
        return photos;
      });
      //retunr photos

      await Promise.all([promise, promise1]).then((values) => {
        console.log(values);

        blured = values[0][1];
        photos = values[1];
        expired = values[0][0];
      });

      //when the getit is finished we run the bottom codes
      photos.forEach((photo) =>
        blured.forEach((blur) => {
          if (
            photo.plan === blur.Pledgeid &&
            blur.PaidUser === nolocation /*locationornot[0]?.id*/ //location.state[0]?.id
          ) {
            photo.blur = false;
          }
        })
      );
      console.log(photos);
      //  setTimeout(() => {
      setPremiumphotos(photos);

      //console.log(expired.id);
      // console.log(expired[0].id);

      /*expired.forEach((value) =>{
          let userDocumentRef = doc(
        db,
        "users",
        userid,
        "PledgeHold",
        value.id
      );
       deleteDoc(userDocumentRef);
      
       also decrement the pledgeplan subscirber
     } )
       */

      //  });
      //  });
    };
    //for the expired shiz delete action
    //clean up this whole useeffect and pledgeaction shiz
    //anyway to run foreach within promise1 to avoid needless loops or nah?
    //test different images being locked, and unlocked 1 pic blured other pic unblured
    //back to top slash slash
    Pledgeaction();
  }, []);

  const askunblur = (e) => {
    let which = premiumphotos.findIndex(
      (element) => e.target.id === element.id
    );

    console.log(premiumphotos[which]);
    // if (premiumphotos[which].blur) {
    console.log(premiumphotos[which].plan);
    setUnblur(premiumphotos[which].plan);
    setTimeout(() => {
      const element = ref1.current;
      console.log(element);
      element.scrollIntoView();

      setTimeout(() => {
        //if you have multiple plans, make sure it scrolls side ways as well
        setUnblur("");
      }, 2000);
    });
    //  }
  };

  //const Fullscreen = (e) => {};
  const Closefullscreen = (e) => {
    setFullscreentarget([]);
  };

  return (
    <div
      style={{
        position: fullscreentarget.length > 0 && "fixed",
        width: fullscreentarget.length > 0 && "100%",
      }}
    >
      {fullscreentarget.length === 0 ? (
        <Link //to={`/UserView/${userid}`}
          to={
            /* location?.state?.[1]*/ locationornot[1] !== "Direct"
              ? `/Connect`
              : `/UserView/${userid}`
          }
          state={
            /*location?.state?.[1]*/ locationornot[1] === "Direct"
              ? "SeenPledge"
              : undefined
          }
        >
          <img
            style={{ zIndex: 2, position: "absolute", top: 5, left: 4 }}
            src="/Arrow1.svg"
          />
        </Link>
      ) : (
        <div
          style={{
            position: "fixed",

            width: "100%",
            height: "100%",
            //overflowX: "clip",
            zIndex: 2,
          }}
        >
          {
            //1 main mission, (1 copy and paste mission, or Testing), 1 reusable component mission, 1 iphone mission, 1 css
            ////////////////////////////////////
            //ちゆら incorporate
            //りさん incorporate
            //how to know you are sending dm to にゅん kinda empty dm box after click
            //the design of the error for sign up is a lil ugly
            //(
            //(can do lil bit at a time)
            //::::::work on fourfiles for tempfiles resuable compoenent
            //::::::fourfiles for posts/comments videos and images files as separate re usuable component
            //::::::displaying alldisplay and simposts reusable component, user, userview,connect,notification
            //::::::exportable functions for sendcomment
            //::::::exportable functions for deletecomment
            //::::::any other reusable component
            //put +81 in signup page in a different input and combine it together upon submit so people dont delete them all the time
            //)
            //////////////////////////////////////////////////
            //full screen images connect,notification
            //full screen comments in userview, user, connect,notification
            //full screen userview headers
            //implement videos being fullscreen,
            //full screen videos in userview, user, connect,notification
            //full screen comments videos in userview, user, connect,notification
            //make sure that when turned sideways on mobile videos are able to be in perfect movie style
            //make sure you can do this in connect, user page, user view page as well
            //check in mobile
            //Lowcontrol slash slash
            //for post that have crazy amount of text make sure you cut it off, but once clicked on it the alldisplay should extend that overflowed amount
            //try this in userview first before doing it in the connect
            //go back to confsend slash slash in messages
          }
          {fullscreentarget[1] === "image" ? (
            <FullScreenImage
              file={fullscreentarget[0]}
              // type={fullscreentarget[1]}
              close={Closefullscreen}
              shape={fullscreentarget[2]}
            />
          ) : (
            fullscreentarget[1] === "video" && (
              <FullScreenVideo
                file={fullscreentarget[0]}
                // type={fullscreentarget[1]}
                close={Closefullscreen}
                shape={fullscreentarget[2]}
              />
            )
          )}
        </div>
      )}

      <div className="picline">
        <img
          src={
            viewinfo?.Pledgepic ? viewinfo.Pledgepic : viewinfo.Profile_image
          }
          className="Pledgepic"
        />
      </div>
      <div
        className="allplans1"
        style={{ justifyContent: premiumplans.length < 4 && "center" }}
      >
        {premiumplans.map((premiumplan) => (
          <div
            key={premiumplan.id}
            onClick={(e) =>
              navigate(`/PledgeSubscribe/${userid}`, {
                state: [
                  premiumplan,
                  /*location?.state?.[0]*/ locationornot[0],
                  viewinfo,
                ],
              })
            }
          >
            <div
              ref={unblur === premiumplan.id ? ref1 : null}
              className="premiumplans"
              style={{ paddingLeft: premiumplans.length > 1 && 10 }}
            >
              <div
                className="premiumplans1"
                style={{
                  animation:
                    unblur === premiumplan.id && "flash 0.5s 5" /*"forwards" */,
                }}
              >
                <div className="details">
                  <div
                    className="details1"
                    lang={
                      /^[a-zA-z]+$/.test(premiumplan.title) !== true && "ja"
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flex: 1,
                      fontWeight: 200,
                      color: "gold",
                    }}
                  >
                    {premiumplan.title}
                  </div>
                </div>
                <div className="priceduration">
                  {premiumplan.price}円/{premiumplan.duration}
                </div>

                <div className="subscribeline">
                  <img style={{ width: 16 }} src="/Profile4.svg" />
                  <div style={{ fontSize: 9, marginLeft: 3 }}>
                    {premiumplan.subscribers}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: 5 }} className="Premiumoption">
        <img style={{ width: 30, marginTop: 8 }} src="/Photo.svg" />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: premiumphotos.length > 3 && "wrap",
        }}
      >
        {premiumphotos.map((premiumphoto) => (
          <div key={premiumphoto.id}>
            <img
              draggable="false"
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              id={premiumphoto.id}
              src={premiumphoto.image}
              onClick={
                premiumphoto.blur
                  ? askunblur
                  : () => setFullscreentarget([premiumphoto.image, "image"])
              }
              style={{
                WebkitTouchCallout: "none",

                width:
                  premiumphotos.length === 1
                    ? 240
                    : premiumphotos.length === 2
                    ? 170
                    : premiumphotos.length > 2
                    ? 110
                    : null,
                height:
                  premiumphotos.length === 1
                    ? 220
                    : premiumphotos.length === 2
                    ? 150
                    : premiumphotos.length > 2
                    ? 90
                    : null,

                borderRadius: premiumphotos.length === 2 ? 40 : 15,
                marginTop: 8,
                marginLeft: 10,
                filter:
                  premiumphoto.blur &&
                  `blur(${
                    premiumphoto.blurlevel +
                    1 /*add more blur level as need be*/
                  }px)`,

                // pointerEvents: "none",
                userSelect: "none",
                WebkitUserSelect: "none",
                msUserSelect: "none",
                MozUserSelect: "none",
              }}
            />
            {premiumphoto.blur && (
              <div className="pledgeoptions">
                <img
                  src="/Lock.svg"
                  style={{
                    width: 15,
                    position: "relative",
                    bottom:
                      premiumphotos.length === 1
                        ? 222
                        : premiumphotos.length === 2
                        ? 153
                        : premiumphotos.length > 2 && 95,

                    left:
                      premiumphotos.length === 1
                        ? 228
                        : premiumphotos.length === 2
                        ? 132
                        : premiumphotos.length > 2 && 102,
                  }}
                  id={premiumphoto.id}
                  onClick={askunblur}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div
        className="suggestiontext"
        style={{ fontSize: 15, textAlign: "center", marginTop: 70 }}
      >
        おすすめユーザー
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          marginTop: 20,
        }}
      >
        {pledgesuggestions[0]?.map((suggestion) => (
          <div
            onClick={() => {
              navigate(`/Pledge/${suggestion.id}`, {
                // state: [userinfo, "Direct"],
              });
              window.location.reload();
            }}
            key={suggestion.id}
          >
            <div
              style={{
                // textAlign: "center",
                // marginLeft: 4,
                // marginBottom: 8,
                // color: "deeppink",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  //textAlign: "center",
                  fontSize: 13,
                  marginBottom: 8,
                  //color: "deeppink",
                }}
              >
                <span className="suggestionname">{suggestion.User_Name}</span>
              </div>
              <img
                style={{ width: 70, borderRadius: 30 }}
                src={suggestion.Profile_image}
              />
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          marginTop: 10,
          //marginLeft: 38,
          marginLeft: "8%",
        }}
      >
        {pledgesuggestions[1]?.map((images, index) => (
          <div style={{ display: "flex", width: "100%" }} key={index}>
            {images.map((image) => (
              <div
                onClick={() => {
                  navigate(`/Pledge/${pledgesuggestions[0]?.[index]?.id}`, {
                    // state: [userinfo, "Direct"],
                  });
                  window.location.reload();
                }}
                key={image.id}
              >
                <img
                  draggable="false"
                  onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }}
                  style={{
                    WebkitTouchCallout: "none",
                    userSelect: "none",
                    WebkitUserSelect: "none",
                    msUserSelect: "none",
                    MozUserSelect: "none",

                    ///////////////////////////////////////////////////
                    //this unclikable feature for 非公開 images like   userSelect: "none",
                    /*WebkitUserSelect: "none",
                     */
                    //should be bunched together and reusuable
                    //if there is 1 photo, vs 2 photos change the margin left percentage or pixel in the pledgesuggestion
                    //2 images are too small compared to the 1 image
                    //either inlarge it or make the 1 image smaller
                    //navigate states sorted
                    //what if only 1 photo on the left? andno photo on the right?
                    //messes up style
                    //styles repeating so use function

                    //duplicates coming in

                    //the bottom of the page needs to have a lil bit of space
                    //images sould be a lil bit to the left? lil cricket

                    //2 images on the left, 1 image on the right little bit off center for the right imaage
                    //when clicking on blured images and you scroll to view, instead we should scroll to top
                    //exclude the pledge person to be in pledge suggestion
                    //only 1 users images coming, in but this could be because of the setstate for loop at the top
                    //the whole page is overflowing to the left fix it without messing up the scroll feature of the pledge plans overflow x
                    /////////////////////////////
                    //clean up and perfect pledgesuggestion function
                    //pledgesuggestion should run at the bottom of useeffect instead of at the top?
                    //maybe consider combining the getdoc array with getdocs array
                    //so you dont have to have 2 separate pledgesuggestion maps
                    //combine the 2 maps? or nah
                    //navigating should be combined by block of mapped.,
                    //user name, Profile_image, pledge photos should be 1 block clickable to diff url and window reload not
                    //3 separate clickable shiz

                    //the user name should be clickable to go to thier pledge page
                    //the suggestion images and suggestion user profile_image not aligning since
                    //its not wrapped together, once you make it wrapped together align items center?
                    //finish rest of slash slash on page
                    //move on to next pledge shiz from iphone

                    width: images[1] ? 90 : 150,
                    height: images[1] ? 90 : 150,
                    borderRadius: 20,
                    filter: `blur(6px)`,
                  }}
                  src={image.image}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pledge;
